<section class="payment-card-wrapper row g-2">
  @for(savedCard of getPaginatedCards(); track $index ; let last =$last){
  <div class="col-12 col-md-6 col-xl-4">
    <div
      class="card-wrapper border-radius-8"
      [ngStyle]="{ 'background-color': backgroundColor ? backgroundColor : '' }"
    >
      <div
        class="d-flex justify-content-between align-items-center card-header"
      >
        <div *ngIf="hasContent; else defaultContent" >
          <ng-content select="[custom-header-text]"></ng-content>
        </div>
        <ng-template #defaultContent>
          <p
            class="font-weight-600 fs-16"
            [ngClass]="{ 'expired-tag': savedCard?.card?.expired }"
          >
            {{ savedCard?.card?.expired ? "Expired" : "Select This Card" }}
          </p>
        </ng-template>
        @if(!disableSelection){
        <mat-radio-button
          [ngClass]="{ 'autopay-radio': autoPay, 'disabled-card':savedCard?.card?.expired }"
          [checked]="savedCard?.paymentMethodId == markedCard?.paymentMethodId"
          (change)="emitSlectedCard(savedCard)"
          [disabled]="savedCard?.card?.expired"
          >{{ autoPay ? "Autopay" : "" }}</mat-radio-button
        >
        }
      </div>
      <div
        class="d-flex justify-content-start align-items-center payment_card_content" [ngClass]="{'disabled-card':savedCard?.card?.expired}"
      >
        @if(['jcb','discover','diners','visa','mastercard','amex'].includes(savedCard?.card?.cardBrand||"")){
        <img
          [src]="cardIcon + savedCard?.card?.cardBrand + '.png'"
          alt=""
          height="20px"
          class="pl-1"
        />
        }@else {
        <mat-icon>credit_card</mat-icon>
        }
        <p class="fs-16 font-weight-500 spacing-12">
          Ending in&#58;{{ savedCard?.card?.cardNo }}
        </p>
        <p class="fs-16 font-weight-500 spacing-12">
          Exp:{{ savedCard?.card?.cardExpMonth }}&#45;{{
            savedCard?.card?.cardExpYear
          }}
        </p>
      </div>
    </div>
  </div>
  } @if(!hidePagination){
  <mat-paginator
    class="paginator-class justify-content-end col-12"
    [length]="savedDefaultPayments?.length"
    [pageSize]="pageSize"
    (page)="onInnerPageChange('', $event)"
    [showFirstLastButtons]="true"
  >
  </mat-paginator
  >}
</section>
