import { inject } from "@angular/core";
import { ActivatedRoute, CanActivateFn, Router } from "@angular/router";
import { AuthService } from "@auth0/auth0-angular";
import { ConfigService } from "../shared/services/config/config.service";
import { ProfileService } from "../features/accounts/services/profile/profile-services.service";
import { AuthenticationService } from "../shared/services/authentication/authentication.service";

export const isRegisteredGuard: CanActivateFn = async (route, state) => {
  const router: Router = inject(Router);
  const profileService = inject(ProfileService);
  const authService = inject(AuthenticationService);
  if (!(await authService.isAuthenticated())) {
    return false;
  }
  let userIsRegistered = await profileService.userIsRegistered();

  if (state.url == "/account/create" && !userIsRegistered) {
    await profileService.getUserProfileValue("accountId");
    userIsRegistered = await profileService.userIsRegistered();
  }

  console.log("Checking if user is registered", userIsRegistered);
  if (userIsRegistered) {
    if (
      state.url == "/account/create" &&
      profileService.selectedAccount().myussUserRole &&
      !localStorage.getItem("signUpRequestId")
    ) {
      return router.navigate(["home"]);
    }
    return true;
  } else {
    if (state.url !== "/account/create") {
      return router.navigate(["account/create"]);
    } else {
      return true;
    }
  }
};
