import { Component, Input, input } from "@angular/core";

@Component({
  selector: "app-link-card",
  standalone: true,
  imports: [],
  templateUrl: "./link-card.component.html",
  styleUrl: "./link-card.component.scss",
})
export class LinkCardComponent {
  @Input({required:true})title:string;
  @Input({required:true})navigationLink:string;
  @Input({required:true})icon:string;

  onClick() {
    window.open(this.navigationLink, "_blank");
  }
}
