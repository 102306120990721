import { NgClass } from "@angular/common";
import {
  Component,
  ContentChild,
  ElementRef,
  HostListener,
  Input,
} from "@angular/core";

@Component({
  selector: "app-custom-stepper",
  standalone: true,
  imports: [NgClass],
  templateUrl: "./custom-stepper.component.html",
  styleUrl: "./custom-stepper.component.scss",
})
export class CustomStepperComponent {
  private _currentStep: number = 0;
  //Steps to be displayed in the stepper
  @Input({ required: true }) steps: string[] = [];
  //Current step the stepper is on
  @Input({ required: true })
  set currentStep(value: number) {
    if (value < 0) {
      this._currentStep = 0;
    } else if (value >= this.steps.length) {
      console.warn(
        `currentStep (${value}) exceeds the number of steps (${this.steps.length}). Resetting to last step.`
      );
      this._currentStep = this.steps.length - 1;
    } else {
      this._currentStep = value;
    }
  }

  @ContentChild('text-content', { static: false })textContent!: ElementRef;

  //mobileWidth is used to determine if the stepper should be displayed as a vertical stepper
  mobileWidth: boolean = false;

  constructor() {
    if (window.innerWidth <= 600) {
      this.mobileWidth = true;
    } else {
      this.mobileWidth = false;
    }
  }

  get currentStep(): number {
    return this._currentStep;
  }

  //Function to handle window resize event
  @HostListener("window:resize") onWindowResize(): void {
    if (window.innerWidth <= 600) {
      this.mobileWidth = true;
    } else {
      this.mobileWidth = false;
    }
  }
}
