<section class="card-wrapper"  [ngClass]="{ 'custom-card': isSegmentedBorder }"
[style.background-color]="backgroundColor" [style.border-color]="borderColor">
  <!-- card header -->
  <div
    class="card-header"
    [style.border-color]="borderColor"
    [ngClass]="{ 'border-none': !showWhenCollapsed && !isExpanded,
            'custom-header': isSegmentedBorder

    }"
    
  >
    <div class="d-flex justify-content-between align-items-center">
      <div class="w-100">
        <ng-content select="[card-header]"></ng-content>
      </div>
      <!-- open / collapse btn -->
      @if (allowCollapse|| showCollapseIcon) {
      <div
        (click)="toggleAccordian()"
        class="dots d-flex justify-content-center align-items-center"
      >
        <mat-icon [style.color]="collapseIconColor">more_vert</mat-icon>
      </div>
      }
    </div>
  </div>
  <!-- card content -->
  <div class="card-content">
    <div
    class="w-100"
      [ngClass]="{
        'accordion-expanded': isExpanded && allowCollapse,
        'accordion-collapsed': !isExpanded && allowCollapse,
        'p-2':isExpanded && allowCollapse|| isExpanded
      }"
    >
      <ng-content select="[card-content]"></ng-content>
    </div>
  </div>
  <!-- content that shows up when card is collapse -->
  @if(showWhenCollapsed && allowCollapse){
  <div class="p-2 w-100" [ngClass]="{'display-none': isExpanded,}">
    <ng-content select="[card-collapse-content]"> - </ng-content>
  </div>
  }

  <!-- card footer -->
  @if (footer && isExpanded) {
  <div class="w-100 card-footer-wrapper" [style.border-color]="borderColor">
    <div class="card-footer py-2 w-100">
      <ng-content select="[card-footer]"></ng-content>
    </div>
  </div>
  }
</section>
