import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { ThemeModule } from "src/app/theme/theme.module";
import { NgModule } from "@angular/core";
import { mySiteServicesRoutes } from "./my-sites-services.routes";
import { SharedModule } from "src/app/shared/shared.module";
import { MySitesServicesWrapperComponent } from "../mySitesServices/components/my-sites-services-wrapper/my-sites-services-wrapper.component";

@NgModule({
  declarations: [MySitesServicesWrapperComponent],
  exports: [MySitesServicesWrapperComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    ThemeModule,
    RouterModule.forChild(mySiteServicesRoutes),
  ],
})
export class MySiteServicesModule {}
