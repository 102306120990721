import { BreakpointObserver } from "@angular/cdk/layout";
import { Component, effect, HostListener, ViewChild } from "@angular/core";
import { MatDrawer, MatDrawerContent } from "@angular/material/sidenav";
import { NavigationEnd, Router } from "@angular/router";
import { result } from "lodash";
import { AppSettings } from "src/app/app.settings";
import { Settings } from "src/app/app.settings.model";
import { ProfileService } from "src/app/features/accounts/services/profile/profile-services.service";
import { SubscriptionsContainer } from "src/app/theme/utils/subscriptions-container";
import { AppTitleService } from "../../services/app-title.service";
import { MySitesServicesService } from "../../services/my-sites-services.service";

@Component({
  selector: "app-my-sites-services-wrapper",
  templateUrl: "./my-sites-services-wrapper.component.html",
  styleUrl: "./my-sites-services-wrapper.component.scss",
})
export class MySitesServicesWrapperComponent {
  @ViewChild("sidenav") sidenav: any;
  @ViewChild("backToTop") backToTop: any;
  @ViewChild("mainContent") mainContent: MatDrawerContent;
  subs = new SubscriptionsContainer();
  public showSidenav: boolean = false;
  @ViewChild("notificationDrawer", { static: false })
  notificationDrawer!: MatDrawer;
  isLargeScreen: boolean = true;
  isNotificationMenuOpened = false;
  showToggle = true;
  copyrightDate = new Date().getFullYear();
  public settings: Settings;
  isMobile: boolean;
  appTitle: string;

  constructor(
    public appSettings: AppSettings,
    public router: Router,
    public profileService: ProfileService,
    private breakpointObserver: BreakpointObserver,
    private appTitleService: AppTitleService,
    private mySiteSrvicesService:MySitesServicesService
  ) {
    this.settings = this.appSettings.settings;
    effect(() => {
      this.appTitle = this.appTitleService.appTitleSignal();
      if(mySiteSrvicesService.toggleMenu()==false && this.sidenav?.opened){
        this.sidenav.toggle();
      }
    });
  }

  ngOnInit(): void {
    // Observe screen size changes
    this.updateSettings();
    this.breakpointObserver
      .observe(["(max-width: 1800px)"])
      .subscribe((result) => {
        this.isLargeScreen = !result.matches;

        // Close sidebars on small screens
        if (!this.isLargeScreen) {
          if (this.notificationDrawer) this.notificationDrawer.close();
          this.isNotificationMenuOpened = false;
        } else {
          this.isNotificationMenuOpened = true;
          if (this.notificationDrawer) this.notificationDrawer.open();
        }
      });
    this.breakpointObserver
      .observe(["(max-width: 1000px)"])
      .subscribe((result) => {
        this.showToggle = !result.matches;
      });

      this.updateToggleSignal(this.sidenav?.opened);
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.settings.loadingSpinner = false;
    }, 300);
    if (window.innerWidth <= 960) {
      this.sidenav?.close();
    }
  }

  // drawer collapse function 
  collapse() {
    const drawer = document.querySelector(".mat-drawer-content") as HTMLElement;
    if (!this.isMobile) {
      if (this.settings.menuType == "mini") {
        this.settings.menuType = "default";
        drawer ? (drawer.style.marginLeft = "240px") : null;
        return;
      }

      this.settings.menuType = "mini";
      drawer ? (drawer.style.marginLeft = "120px") : null;
      return;
    } else {
      this.settings.menuType = "default";
      drawer ? (drawer.style.marginLeft = "0px") : null;
      this.sidenav.toggle();
      
    }
  }
  

  @HostListener("window:resize") onWindowResize(): void {
    this.updateSettings();
  }

  updateSettings() {
    if (window.innerWidth <= 960) {
      this.isMobile = true;
      this.settings.sidenavIsOpened = false;
      this.settings.sidenavIsPinned = false;
      this.settings.menu = "vertical";
    } else {
      this.isMobile = false;
      this.settings.sidenavIsOpened = true;
      this.settings.sidenavIsPinned = true;
    }
  }

  toggle() {
    this.notificationDrawer.toggle();
    this.isNotificationMenuOpened = !this.isNotificationMenuOpened;
  }
  onDrawerStateChange(isOpen: boolean): void {
    this.isNotificationMenuOpened = isOpen;
  }
  updateToggleSignal(event){
    this.mySiteSrvicesService.setToggleMenuSignal(event);
  }
}
