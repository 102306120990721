import { Component } from '@angular/core';

@Component({
  selector: 'app-easy-pay',
  standalone: true,
  imports: [],
  templateUrl: './easy-pay.component.html',
  styleUrl: './easy-pay.component.scss'
})
export class EasyPayComponent {

}
