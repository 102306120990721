<div class="my-google-map" ngClass.xs="p-0">
  <div *ngIf="showLabel" class="mb-1 p-2 px-3 label">
    <h3>Active Contracts Location</h3>
  </div>
  <google-map [options]="options">
    <map-marker
      #markers="mapMarker"
      *ngFor="let marker of contractList"
      [position]="{
        lat: marker.shippingAddressLat,
        lng: marker.shippingAddressLng
      }"
      (mapClick)="openInfoWindow(markers, marker)"
    ></map-marker>
    <map-info-window>
      <div>
        <div fxLayout="row" fxLayoutAlign="space-between start">
          <p class="order-title pb-2">Orders</p>
          <img
            src="../../../../assets/v2-asset/close-icon.svg"
            alt="Close the window"
            class="close-icon"
            (click)="close()"
          />
        </div>
        <div class="info-window-content pb-2">{{ shippingAddress || "-" }}</div>
        <div
          fxLayout="row wrap"
          fxLayoutAlign="start center"
          fxLayoutGap="8px"
          class="vertical-gap"
        >
          <ng-container
            *ngFor="let entry of orderAndContractIds; let i = index"
          >
            <div
              class="order-card px-2 py-1"
              (click)="openOrderSummary(entry.contractId)"
            >
              <p class="order-number">{{ entry.orderId }}</p>
            </div>
          </ng-container>
        </div>
      </div>
    </map-info-window>
  </google-map>
</div>
