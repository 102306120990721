<div class="custom-card">
  <!-- <div class="bg-primary" style="margin-bottom: 1rem" fxLayout="row" fxLayoutAlign="start center">
            <h3 style="padding: 0.5rem 0.8rem">Confirmation</h3>
        </div> -->

  <div class="message-wrapper">
    <div
      class="d-flex flex-column justify-content-center p-4"
      *ngIf="!data?.message"
    >
      <p>Total quantity for the order cannot exceed <b>10</b>.
      
        You cannot add more than <b>{{ data?.addUnitLimit }}</b> quantity to the
        product.
      
      Please contact {{ phoneNumber }}.</p>
    </div>
    <div class="p-4 text" *ngIf="data?.message">
      <span>{{ data?.message }}</span>
    </div>
    <!-- <mat-divider class="my-2 mt-3"></mat-divider> -->

    <div class="btnDiv w-100 py-2" fxLayoutAlign="center center">
      <button
        class="btnsize"
        mat-raised-button
        color="primary"
        (click)="onClose()"
      >
        Ok
      </button>
    </div>
  </div>
</div>
