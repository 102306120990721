import { HttpParams } from "@angular/common/http";
import { Injectable, signal } from "@angular/core";
import { MySitesServicesService } from "../../mySitesServices/services/my-sites-services.service";
import { map, catchError, firstValueFrom } from "rxjs";
import { ConfigService } from "src/app/shared/services/config/config.service";
import { Stripe } from "@stripe/stripe-js";
import { confirmEasyPayRequestPayload, easyPayStateModel } from "src/app/v2/models/easy-pay-details-model";

@Injectable({
  providedIn: "root",
})
export class EasyPayService {
  stripePublishableKey: string;
  easyPayStateSignal= signal<easyPayStateModel | undefined>(undefined);

  constructor(
    private api: MySitesServicesService,
    private configService: ConfigService
  ) {
    this.stripePublishableKey = this.configService.getConfigProperty(
      "STRIPE_PUBLISHABLE_KEY"
    );
  }

  //get stripe customer id
  getCustomerId(reqBody: any) {
    return this.api.post(`stripe/customer`, reqBody).pipe(
      map((res: any) => {
        if (res["message"] === "Success" || res["status"] === 1000) {
          return res["data"];
        } else {
          throw res["message"];
        }
      }),
      catchError((err) => {
        throw err;
      })
    );
  }

  //Get saved cards against stripe customer ID
  fetchCards(customerId: string) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append("stripe_customer_id", customerId);
    return this.api
      .get(`stripe`, {
        params: queryParams,
      })
      .pipe(
        map((res) => {
          if (res.status === 1000) {
            return res["data"].paymentMethods;
          }
          return null;
        }),
        catchError((err) => {
          throw err;
        })
      );
  }

  async getPaymentIntent(customerId: string) {
    const resp = await firstValueFrom(
      this.api
        .post(`stripe/customer/setup-intent`, {
          customer_id: customerId,
        })
        .pipe(
          catchError((err) => {
            throw err;
          })
        )
    );
    return resp?.data.client_secret;
  }

  /**
   * Retrives payement intent
   * @param stripe
   * @param clientSecret
   */
  retrivePayementIntent(stripe: Stripe, clientSecret: string): Promise<string> {
    // this.loadingService.setLoader(true);

    return new Promise((resolve, reject) => {
      let interval = setInterval(() => {
        stripe
          .retrieveSetupIntent(clientSecret)
          .then((setupIntent) => {
            let status = setupIntent.setupIntent?.status;

            if (
              status === "succeeded" &&
              setupIntent.setupIntent?.payment_method
            ) {
              const paymentMethodId = setupIntent.setupIntent?.payment_method;
              console.log("succeeded");
              console.log("paymentMethodId", paymentMethodId);
              console.log("paymentMethodId", setupIntent);
              clearInterval(interval);
              resolve(paymentMethodId as string); // Resolve the Promise with paymentMethodId
            }
          })
          .catch((error) => {
            clearInterval(interval);
            reject(error); // Reject the Promise if there's an error
          });
      }, 2000);
    });
  }

  updateEasyPayState(state:easyPayStateModel){
    this.easyPayStateSignal.set(state);
  }

    // api to update payment method
    updatePaymentMethod(data: confirmEasyPayRequestPayload) {
      return this.api.post(`contracts/confirm-easypay`, data).pipe(
        map((res) => {
          if (res["status"] === 1000) {
            return res;
          }
          return null
        }),
        catchError((err) => {
          console.error(`error getting contracts: ${JSON.stringify(err)}`);
          throw err;
        })
      );
    }

}
