<div fxLayout="column" fxLayoutAlign="center center" class="background h-100">
  <img src="../../../../assets/img/USS-Logo-1 2.png" [height]="50" [width]="100" />
  <p class="title">Welcome to {{applicationTitle}}!</p>
  <p class="title2 pb-3">{{applicationTitle}} is the secure United Site Services platform for you to manage your account information, track orders, make payments and more. </p> 
  <p class="title2">Enter your delivery zip code below to create an account</p> 
  <div class="input">
    <form [formGroup]="form" (ngSubmit)="onSubmit(form.value)">
      <label>
        <!-- input filed for postal code -->
        <input
          id="inputTag"
          required
          #input
          placeholder="Enter Delivery Zip Code"
          type="text"
          [maxLength]="5"
          (keyup)="onkey(input.value)"
          name="search"
          formControlName="postalCode"
        />
        <!-- submit button -->
        <button type="submit">Submit</button>
      </label>
    </form>
    <!-- for showing error -->
    <div *ngIf="error || error2">
      <div class="square"></div>
      <div class="span" fxLayoutAlign="start center">
        <mat-icon>error_outline</mat-icon>
        <span *ngIf="error">Please enter a valid 5-digit ZIP code.</span>
      </div>
    </div>
  </div>

  <p class="textcolor py-2">OR</p>
  <p class="textcolor  py-2" id="paragraphTag">
    Already have an account?<a (click)="login()" class="login px-1">Sign in</a>
  </p>
</div>
<!-- loader -->
<div
  *ngIf="loader"
  fxLayout="column"
  fxLayoutAlign="center center"
  class="user-spinner"
>
  <mat-spinner color="primary" [diameter]="50"></mat-spinner>
</div>

