import { Injectable, effect, signal } from "@angular/core";
import {
  BehaviorSubject,
  Observable,
  Subject,
  Subscription,
  exhaustMap,
  interval,
  startWith,
  takeUntil,
} from "rxjs";
import {
  BillingAddress,
  Contact,
  QuoteState,
} from "../../../models/quoteState-model";
import { ProfileService } from "../../accounts/services/profile/profile-services.service";
import { UserProfile } from "../../../models/profile-model";
import * as bcrypt from "bcryptjs";
import {
  CreateQuoteReq,
  CreateQuoteResData,
  DocumentResData,
} from "../../../models/unit-and-services-model";
import { QuoteCreationService } from "./quote-creation.service";
import {
  AncillaryService,
  Asset,
  Service,
  lineItems,
} from "../../../models/product-model";
import { ToastrService } from "ngx-toastr";
import { MatDialog } from "@angular/material/dialog";
import { SurpassedDatePopupComponent } from "src/app/shared/components/dialogbox/surpassed-date-popup/surpassed-date-popup.component";
import { SiteDetailsResponseData } from "src/app/models/site-details-model";
import { BillingDetailsResData } from "src/app/models/billing-details-model";
import { ApiRespDTO } from "src/app/models/api.model";
import { Router } from "@angular/router";
import { ConfigService } from "src/app/shared/services/config/config.service";
import {
  QuoteLineGrouped,
  QuoteLineModel,
} from "src/app/models/quote-line-model";
import { ValidationErrors } from "@angular/forms";

import { JobSiteLocation, JobsiteModel } from "src/app/models/jobsite-model";
import { ProjectService } from "../../projects/services/project.service";
import { QuoteModel } from "src/app/models/quote-model";
@Injectable({
  providedIn: "root",
})
export class QuoteStateService {
  public currentQuote$ = new BehaviorSubject<QuoteState>(new QuoteState());
  public intervalStop$: Subject<void> = new Subject<void>();
  public intervalSubscription$: Subscription;
  progressToastrCalled: boolean = false;
  loading: boolean = false;

  public multiLocationData = signal([] as QuoteLineGrouped[]);
  bundleData: QuoteLineModel[];

  constructor(
    public profileService: ProfileService,
    private quoteCreationService: QuoteCreationService,
    public toastr: ToastrService,
    private box: MatDialog,
    public router: Router,
    private configService: ConfigService,
    private projectService: ProjectService
  ) {
    effect(() => {
      const account = this.profileService.selectedAccount();
      this.subscribeToProfileService(account);
    });
    effect(()=>{
      console.log("test1 quoteCreation : -",this.multiLocationData())
    })
  }

  loadCurrentQuoteFromAPI(quoteId: string) {
    //this.resetState();
    this.quoteCreationService.getQuoteData(quoteId).subscribe((result) => {
      if (result) {
        let quote = this.currentQuote$.value;
        quote = this.mapResultToQuoteState(result, quote);
        this.currentQuote$.next(quote);
        if (
          this.profileService.isAccountInActive() ||
          this.profileService.isReadOnly()
        ) {
          return;
        }
        const currentDate = new Date();
        const newDate = currentDate.setDate(currentDate.getDate() + 1);
        const tomorrowDate = new Date(newDate).toISOString().split("T")[0];
        const startDate = new Date(quote.step1.startDate)
          .toISOString()
          .split("T")[0];
        if (
          tomorrowDate < startDate === false &&
          quote.currentStep > 1 &&
          quote.currentStep < 6
        ) {
          this.box.open(SurpassedDatePopupComponent, {
            minWidth: "30%",
            disableClose: true,
            data: {
              isEnhanceQuote: false,
              showBackButton: quote.currentStep > 2,
              showChangeDateButton: quote.currentStep == 2,
            }
          });
        }
      }
    });
  }

  private subscribeToProfileService(account: Partial<UserProfile>) {
    // set the initial profile state
    this.updateProfileInQuoteState(account);
  }

  private updateProfileInQuoteState(profile: Partial<UserProfile>) {
    this.updateCommonValues({
      userProfile: profile,
      customerType: profile.customerType,
      businessType: profile.businessType,
      accountId: profile.accountId,
    });
  }

  /*
   * update values that not nested
   */
  updateCommonValues(data: Partial<QuoteState>) {
    const currentState = this.currentQuote$.value;

    this.currentQuote$.next({
      ...currentState,
      ...data,
    });
  }

  getCurrentValue() {
    return this.currentQuote$.value;
  }

  resetState() {
    this.currentQuote$.next(new QuoteState());
    // set the initial profile state
    this.updateProfileInQuoteState(this.profileService.selectedAccount());
  }

  public async saveNewQuote() {
    const currentQuote = this.currentQuote$.value;
    const profile = this.profileService.selectedAccount();
    let requestId: string;
    if (!localStorage.getItem("requestId")) {
      requestId = await this.generateRequestID();
      localStorage.setItem("requestId", requestId);
    } else {
      requestId = localStorage.getItem("requestId") as string;
    }

    const address = currentQuote.address.zipcode
      ? currentQuote.address
      : JSON.parse(localStorage.getItem("address") as string);
    const reqData = {
      requestId,
      accountId: profile.accountId,
      address: !address?.addressExist
        ? {
            ...address,
            accountId: profile.accountId,
          }
        : {},
      orderType: currentQuote.step1.orderType,
      zipcode: address?.zipcode,
      billTiming: currentQuote.step1.billTiming,
      billingPeriod: currentQuote.step1.billingPeriod,
      prodSubType: currentQuote.step1.prodSubType,
      contactId: profile.contactId,
      addressExist: address?.addressExist,
      addressId: address?.addressId ? address.addressId : "",
      customerType: profile.customerType,
      businessType: profile.businessType,
      projectId: this.projectService.selectedProject().id || "",
    };
    console.log("project", this.projectService.selectedProject());
    const resp = this.createQuote(reqData);
    return resp;
  }

  createQuote(reqData: CreateQuoteReq) {
    const callBack = () => this.quoteCreationService.createQuote(reqData);

    this.intervalSubscription$ = this.startInterval(
      callBack,
      3000,
      "createQuote"
    );
  }

  mapResultToQuoteState(result: any, quote: QuoteState): QuoteState {
    this.bundleData = [];
    this.multiLocationData.set(this.bundleData);


    quote.quoteModel = result.quoteModel;
    quote.currentStep = result.currentStatus;
    quote.quoteId = result.quoteId;
    quote.quoteName = result.quoteName;
    quote.paymentMethodId = result.paymentMethodId;
    quote.isAutoPay = result.isAutoPay;
    quote.address = {
      ...result.siteAddress,
      addressExist: result?.siteAddress?.addressId ? true : false,
      zipcode: result?.siteAddress?.zipcode,
      placementNotes:
        result.quoteModel.jobSites.length > 0
          ? result.quoteModel.jobSites[0].address.instructions
          : "",
    };
    quote.siteDetailFlag = false;
    quote.step1.startDate = result.startDate;
    quote.step1.endDate = result.endDate;
    quote.step1.estimatedEndDate = result.estimatedEndDate;
    quote.step1.documentId = result.documentId;
    quote.step1.pdfName = result.documentName;
    quote.step1.duration = result.duration;
    quote.step1.productDetails = this.productMapper(result.lineItems);
    if (quote.currentStep > 2) {
      quote.step2.status = "Approved";
    }
    if (!quote.step3.contactData) {
      quote.step3.contactData = new Contact();
    }
    quote.step3.contactData.contactId = result.siteContact?.contactId;
    quote.step3.contactData.firstName = result.siteContact?.firstName;
    quote.step3.contactData.lastName = result.siteContact?.lastName;
    quote.step3.contactData.phone = result.siteContact?.phone;
    quote.step3.contactData.email = result.siteContact?.email;
    quote.step3.SiteDetails = result.siteAddress;
    quote.step4.contactRefId = result.billingContact?.contactId;
    if (!quote.step4.billingAddress) {
      quote.step4.billingAddress = new BillingAddress();
    }
    quote.step4.billingAddress.addressRefId = result?.billingAddress?.addressId;
    quote.step4.billingAddress.address = result?.billingAddress?.address;
    quote.step4.billingAddress.city = result?.billingAddress?.city;
    quote.step4.billingAddress.state = result?.billingAddress?.state;
    quote.step4.billingAddress.zipcode = result?.billingAddress?.zipcode;
    quote.step4.billingAddress.addressExist = result?.billingAddress?.zipcode
      ? true
      : false;
    quote.step4.poNumber = result?.poNumber;
    if (quote.step4.secondaryContactData) {
      quote.step4.secondaryContactData.firstName =
        result?.quoteModel.secondaryContactData.firstName;
      quote.step4.secondaryContactData.lastName =
        result?.quoteModel.secondaryContactData.lastName;
      quote.step4.secondaryContactData.email =
        result?.quoteModel.secondaryContactData.email;
      quote.step4.secondaryContactData.phone =
        result?.quoteModel.secondaryContactData.phone;
      quote.step4.secondaryContactId =
        result?.quoteModel.secondaryContactData?.contactId;
    }
    quote.quoteModel = result.quoteModel;
    if (result.quoteModel.jobSites.length > 0) {
      this.bundleData = this.prepareBundleData(quote.quoteModel.quoteLines);
      this.mapJobitesToBundleData(quote.quoteModel);
    }

    return quote;
  }

  mapJobitesToBundleData(quoteModel: QuoteModel) {
    console.log("quoteModel", quoteModel);
    if (!this.bundleData || this.bundleData.length === 0) {
      this.bundleData = this.prepareBundleData(quoteModel.quoteLines);
    }
    const groupedLocations = quoteModel.jobSites.reduce(
      (groupedLocations, jobSite) => {
        const addressId = jobSite.address.id || "";
        if (groupedLocations[addressId]) {
          groupedLocations[addressId].push(jobSite);
        } else {
          groupedLocations[addressId] = [jobSite];
        }
        return groupedLocations;
      },
      {}
    );
    this.bundleData && this.bundleData.forEach((bundle : QuoteLineGrouped) => {
      Object.keys(groupedLocations).forEach((locationId) => {
        const location = groupedLocations[locationId][0];
        this.bundleData[this.bundleData.indexOf(bundle)][
          `newLocation${locationId}`
        ] = {
          ...location,
          quantityQuoted: 0,
          columnId: `newLocation${locationId}`,
        };
      });
    });

    if (groupedLocations && Object.keys(groupedLocations).length > 0) {
    Object.keys(groupedLocations).forEach((locationId : string) => {
      groupedLocations[locationId].forEach((location: JobSiteLocation) => {
        const index = this.bundleData && this.bundleData.findIndex(
          (bundle) =>
            bundle.product.id === location.productDetails.bundleId &&
            bundle.quoteLineId === location.quoteLineId
        );
        
          if (index !== -1 && this.bundleData[index]) {
            this.bundleData[index][`newLocation${locationId}`] = {
              ...location,
              quantityQuoted: location.quantityQuoted,
              columnId: `newLocation${locationId}`,
    
            };
          }
          else{
            console.error('Could not find a matching bundle for locationId: ${locationId}`');
          }

        
       
      });
    });
  }
  else {
    console.error('Grouped locations are empty or undefined');
  }



    this.multiLocationData.set(this.bundleData);
  }

  test(quoteModel: QuoteModel) {
    this.bundleData = [];
    
    this.mapJobitesToBundleData(quoteModel);
  }

  productMapper(productsFormApi: lineItems[]) {
    const products = productsFormApi?.map((bundle) => {
      return {
        productIdBundle: bundle.bundleId,
        bundleName: bundle.bundleName,
        bundleProductCode: bundle.bundleProductCode,
        bundleQty: bundle.bundleQty,
        productIdAsset: this.assetMapper(bundle.assetList[0]),
        productIdService: this.serviceMapper(bundle.serviceList[0]),
        additionalProduct: this.additionalServiceMapper(
          bundle.ancillaryServiceList
        ),
      };
    });
    return products;
  }

  assetMapper(asset: Asset) {
    return {
      ...asset,
      id: asset.assetOptionalId,
      assetOptionalId: asset.id,
    };
  }
  serviceMapper(service: Service) {
    return {
      ...service,
      id: service.serviceOptionalId,
      serviceOptionalId: service.id,
    };
  }
  additionalServiceMapper(additionalServices: AncillaryService[]) {
    return additionalServices.map((additionalService) => {
      return {
        ancillaryServiceName: additionalService.ancillaryServiceName,
        ancillaryServiceOptionalId: additionalService.id,
        ancillaryServiceProductCode:
          additionalService.ancillaryServiceProductCode,
        id: additionalService.ancillaryServiceOptionalId,
      };
    });
  }

  /**
   * generate request id
   * @returns requestId
   */
  async generateRequestID() {
    const currentQuote = this.currentQuote$.value;
    const profile = this.profileService.selectedAccount();
    const requestId = await bcrypt.hash(
      `${profile.accountId}|${currentQuote.address.zipcode}`,
      10
    );
    return requestId;
  }

  /**x`
   * @param apiCall
   * @param intervalTime
   */
  startInterval(
    apiCall: () => Observable<any>,
    intervalTime: number,
    step: string
  ): Subscription {
    this.loading = true;
    this.intervalSubscription$ = interval(intervalTime)
      .pipe(
        startWith(0),
        takeUntil(this.intervalStop$),
        exhaustMap(() => apiCall())
      )
      .subscribe((result) => this.handleResult(result, step));
    return this.intervalSubscription$;
  }

  /**
   * handle result
   */
  handleResult(result: ApiRespDTO | null, step: string) {
    // console.log("API call result", result);

    if (!result) {
      this.stopInterval();
      return;
    }

    const { status, data, message } = result;

    if (status === 1000) {
      this.loading = false;
      switch (step) {
        case "createQuote":
          this.updateInitialQuoteState(data);
          break;
        case "siteDetails":
          this.siteDetailsUpdateState(data);
          break;
        case "billingDetails":
          this.updateBillingState(data);
          break;
        case "generateDocument":
          this.updateGenerateDocumentState(data);
          break;
        default:
          this.stopInterval();
          break;
      }
      this.stopInterval();
    }

    if (status === 1018) {
      this.handleInfoMessage(message);
    }
    return result;
  }
  // comman function for back button enable or disable

  showBackButton(): boolean {
    if (
      this.getCurrentValue().currentStep === 1 ||
      (this.getCurrentValue().currentStep === 2 &&
        this.getCurrentValue().step2.status === "Approved")
    ) {
      return false; // for the back button disable
    } else {
      return true; // for the back button enable
    }
  }

  handleInfoMessage(message: string) {
    if (!this.progressToastrCalled) {
      this.toastr.info(message);
      this.progressToastrCalled = true;
    }
  }

  updateInitialQuoteState(data: CreateQuoteResData) {
    const quoteModel= new QuoteModel()
    quoteModel.projectDetails=this.projectService.selectedProject();
    quoteModel.quoteLines=[];
    quoteModel.jobSites=[];
    this.updateCommonValues({
      quoteId: data.quoteId,
      quoteName: data.quoteName,
      address: {
        ...this.getCurrentValue().address,
        addressId: data.addressId,
      },
      quoteModel
    });
    localStorage.setItem("quoteId", data.quoteId);
  }

  siteDetailsUpdateState(data: SiteDetailsResponseData) {
    const currentState = this.currentQuote$.value;

    this.updateCommonValues({
      ...currentState,
      siteDetailFlag: true,
      currentStep: 4,
      step3: {
        contactData: {
          ...data.contactData,
          contactId: data.contactId ? data.contactId : data.contactRefId,
        },
        SiteDetails: { ...currentState.address, ...data.addressData },
      },
    });
    this.quoteCreationService
      .getQuoteData(currentState.quoteId as string)
      .subscribe((result) => {
        if (result) {
          this.updateCommonValues({
            ...this.getCurrentValue(),
            currentStep: 4,
            quoteModel: result.quoteModel,
          });
        }
      });
    this.toastr.success("Site details saved successfully.");
  }

  async updateGenerateDocumentState(data) {
    const currentState = this.currentQuote$.value;
    if (this.configService.getConfigProperty("QUOTE_HTML")) {
      const requestId = await this.generateRequestID();
      if (currentState.quoteId) {
        this.quoteCreationService
          .getDocumentID(currentState.quoteId as string, requestId)
          .subscribe((res) => {
            this.updateCommonValues({
              ...this.getCurrentValue(),
              isPdfGenerated: false,
              step1: {
                ...currentState.step1,
                documentId: res.data.documentId,
                pdfName: res.data.documentName,
              },
            });
          });
      }
    }

    this.updateCommonValues({
      ...currentState,
      currentStep: 2,
      step1: {
        ...currentState.step1,
        documentId: data.documentId,
        pdfName: data.documentName,
      },
      isPdfGenerated: false,

      quoteModel: data.quoteModel,
    });
    this.router.navigate(["/quotes/quotecreation", currentState?.quoteId]);
  }

  updateBillingState(data: BillingDetailsResData) {
    const currentState = this.currentQuote$.value;
    this.updateCommonValues({
      ...currentState,
      currentStep: 5,
      paymentMethodId: data.paymentMethodId,
      isAutoPay: data.isAutoPay,
      step4: {
        contactRefId: data.contactRefId,
        poNumber: data.poNumber,
        billingAddress: {
          addressRefId: data.addressRefId,
          addressExist: data.addressExist,
          address: data.address?.street,
          city: data.address.city,
          state: data.address.state,
          zipcode: data.address.zipcode,
          
        },
        secondaryContactData: data.secondaryContactData,
        secondaryContactId: data.secondaryContactId || "",
        //secondaryContactExist: data.secondaryContactData ? true : false,
      },
    });
    this.toastr.success("Billing & Payment details saved successfully.");
  }

  /**
   * stops interval
   */
  stopInterval() {
    localStorage.removeItem("requestId");
    localStorage.removeItem("siteDetailsId");
    localStorage.removeItem("siteDetailsReqBody");
    localStorage.removeItem("billingDetailsId");
    localStorage.removeItem("billingDetailsReqBody");
    localStorage.removeItem("confirmQuoteId");
    localStorage.removeItem("confirmQuoteReqBody");
    localStorage.removeItem("generateDocumentId");
    this.intervalStop$.next();
    this.intervalStop$.complete();
    if (this.intervalSubscription$) {
      this.intervalSubscription$.unsubscribe();
    }
  }

  localStorageGetItem(key: string) {
    return localStorage.getItem(key);
  }

  // Define a function to get the poRequired value from userProfileString
  getPoRequiredValue() {
    const account = this.profileService.selectedAccount();
    return account.poRequired;
  }

  prepareBundleData(quoteLines: QuoteLineModel[]): QuoteLineModel[] {
    //it will filter out the bundle, asset, service and ancillary data
    const filteredBundleList = quoteLines?.filter(
      (lineItem: QuoteLineModel) =>
        lineItem["productType"] === "Bundle" ||
        lineItem["productType"] === "Asset" ||
        lineItem["productType"] === "Service" ||
        lineItem["productType"].includes("Ancillary")
    );

    const bundleList = this.groupByBundleData(filteredBundleList);

    return bundleList;
  }

  groupByBundleData(bundleList: QuoteLineModel[]): QuoteLineModel[] {
    //get only bundle data from bundleList
    const bundleData = bundleList.filter(
      (lineItem: {}) => lineItem["productType"] === "Bundle"
    );

    const formattedBundleList = bundleData.map((item, index) => {
      return {
        ...item,
        isSelected: index === 0 ? true : false,
        expandFlag: index === 0 ? true : false,
        assetList: bundleList.filter(
          (assetItem) =>
            assetItem["requiredBy"] === item["quoteLineId"] &&
            assetItem["productType"] === "Asset"
        ),
        services: bundleList.filter(
          (service) =>
            service["requiredBy"] === item["quoteLineId"] &&
            service["productType"] === "Service"
        ),
        additionalServices: bundleList.filter(
          (additionalService) =>
            additionalService["requiredBy"] === item["quoteLineId"] &&
            additionalService["productType"].includes("Ancillary")
        ),
      };
    });

    return formattedBundleList;
  }

  groupBy(location: JobsiteModel[], objectKey1: string, objectKey2: string) {
    const groupedData = location?.reduce((acc, location) => {
      const key1 = location.address[objectKey1] || "-";
      const key2 = location.address[objectKey2] || "-";

      // Group by key1
      acc[key1] = acc[key1] || {};

      // Group by key2 within each key2 group
      acc[key1][key2] = acc[key1][key2] || [];
      acc[key1][key2].push(location);

      return acc;
    }, {});
    console.log("grouped Data", groupedData);
    return groupedData;
  }
  objectKeys(object: Object): string[] {
    return Object?.keys(object);
  }

  ngOnDestroy() {
    this.stopInterval();
    this.intervalSubscription$?.unsubscribe();
  }

  startTimeAndEndTimeValidator(
    startTimeControlValue: any,
    endTimeControlValue: any
  ): ValidationErrors | null {
    if (!startTimeControlValue && !endTimeControlValue) {
      console.log(
        "If both start time and end time are empty, return null",
        startTimeControlValue,
        endTimeControlValue
      );
      return null; // If both start time and end time are empty, return null
    }

    if (
      (!startTimeControlValue && endTimeControlValue) ||
      (startTimeControlValue && !endTimeControlValue)
    ) {
      console.log(
        "If either start time or end time is entered, show incompleteTime error",
        startTimeControlValue,
        endTimeControlValue
      );
      return { incompleteTime: true }; // If either start time or end time is entered, show incompleteTime error
    }

    // If both start time and end time are entered, check for time gap
    const start = new Date(`01/01/2000 ${startTimeControlValue}`);
    const end = new Date(`01/01/2000 ${endTimeControlValue}`);
    const timeDiff = (end.getTime() - start.getTime()) / (1000 * 60 * 60); // difference in hours

    if (timeDiff < 2) {
      console.log(
        "If time gap is less than 2 hours, return insufficientTimeGap error",
        timeDiff
      );
      if (timeDiff < 0) {
        return { invalidTime: true }; // If time gap is in negative hours, return invalidTime error
      }
      return { insufficientTimeGap: true }; // If time gap is less than 2 hours, return insufficientTimeGap error
    }
    return null; // Return null if validation passes
  }

  


}
