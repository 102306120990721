<mat-accordion>
  <mat-expansion-panel hideToggle #expansionPanel [expanded]="isOpened" [disabled]="true">
    
      <mat-expansion-panel-header>
        @if(!isCustomHeader) {
          <div class="expansion-header" [ngClass]="{'flex-wrap row':inLineWithSubtitle}">
            <div class="col-12 d-flex justify-content-between">
            <div class="expansion-attachment">
              <div class="expansion-title">
                <p class="title fs-18 font-weight-500 blue-gray-color mb-2" matTooltip="{{ title }}">{{ title }}</p>
                @if(isOrderIdAndAllSelected){
                <div class="orderId-container">
                  <span>Order ID: {{orderId}}</span>
                </div>
                }
                @if(!inLineWithSubtitle){
                <div class="expansion-subtitle">
                  @if(isIcon){
                    <img [src]="icon" alt="dynamic icon" />
                  }
                  <p class="fs-16 font-weight-400 blue-gray-color">
                    {{ subtitle }}
                  </p>
                </div>
              }
              </div>
              @if(expansionPanel.expanded && isAttachment && !isMobileView){
              <ng-content select="[attachment]"></ng-content>
              }
            </div>
            <div class="d-flex flex-column">
              <div class="expansion-indicator">
                @if(isUnit){
                <div class="units-block px-2 py-0" >
                  <p class="fs-12 font-weight-600 primary-blue">{{ units }} Units</p>
                </div>
                }
                @if(isCommentTitle){
                  <div class="units-block px-2 py-0" >
                    <p class="fs-12 font-weight-600 primary-blue">{{ units }} Comments</p>
                  </div>
    
                }
               
    
                @if(isNoOfUnitSelected){
                  @if(noOfUnitSelected !=0){
                    <div class="no-of-unit-selected">
                      <span>{{noOfUnitSelected}} Unit Selected</span>
                    </div>
                  }@else{
                    <div class="none-selected">
                      <span>None Selected</span>
                    </div>
                  }
                }
                <mat-panel-description (click)="$event.stopPropagation()" class="fs-14 font-weight-400 steel-gray-color">
                  @if(expansionPanel.expanded) {
                  <mat-icon svgIcon="Arrow_Up" class="arrow-icon" (click)="expansionPanel.toggle()"></mat-icon>
                  } @if(!expansionPanel.expanded) {
                  <mat-icon svgIcon="Arrow_Down" class="arrow-down-icon" (click)="expansionPanel.toggle()"></mat-icon>
                  }</mat-panel-description>
              </div>
              @if(isOrderIdAndAllSelected){
              <div class="no-units-selected">
                <ng-content select="[no-of-units-selected]"></ng-content>
              </div>
              }
            </div>
          </div>
            @if(inLineWithSubtitle){
              <div class="expansion-subtitle d-flex align-items-start" [ngClass]="{'col-12':inLineWithSubtitle, 'd-none':!inLineWithSubtitle}">
                @if(isIcon){
                  <img [src]="icon" alt="dynamic icon" />
                }
                <p class="fs-16 font-weight-400 blue-gray-color">
                  {{ subtitle }}
                </p>
                
                 <ng-content select="[mobileAttachment]"></ng-content>      
              </div>
            }
          </div>
        }
        @else{
          <ng-container class="d-flex">
            <mat-panel-description (click)="$event.stopPropagation()">
              <ng-content select="[expansion-header]"></ng-content>   
              @if(expansionPanel.expanded) {
                  <mat-icon svgIcon="Arrow_Up" class="arrow-icon" (click)="expansionPanel.toggle()"></mat-icon>
                  } @if(!expansionPanel.expanded) {
                  <mat-icon svgIcon="Arrow_Down" class="arrow-down-icon" (click)="expansionPanel.toggle()"></mat-icon>
                  }</mat-panel-description>                           
          </ng-container>    
        }
        
      </mat-expansion-panel-header>
    <ng-content select="[main-content]"></ng-content>
  </mat-expansion-panel>
</mat-accordion>