import { CommonModule } from "@angular/common";
import { Component, Input } from "@angular/core";
import { MatDivider } from "@angular/material/divider";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatIconModule, MatIconRegistry } from "@angular/material/icon";
import { MatTooltipModule } from "@angular/material/tooltip";
import { DomSanitizer } from "@angular/platform-browser";
import { HelperFunctionService } from "src/app/theme/utils/helper-function.service";

@Component({
  selector: "app-expansion-panel",
  standalone: true,
  imports: [MatIconModule, MatExpansionModule, CommonModule,MatTooltipModule],
  templateUrl: "./expansion-panel.component.html",
  styleUrl: "./expansion-panel.component.scss",
})
export class ExpansionPanelComponent {
  @Input() title!: string;
  @Input() subtitle!: string;
  @Input() icon: string = "";
  @Input() isIcon: boolean = true;
  @Input() isAttachment: boolean = false;
  @Input() units!: number;
  @Input() isOpened: boolean;
  @Input() isUnit: boolean = false;
  @Input() isNoOfUnitSelected: boolean = false;
  @Input() noOfUnitSelected: number=0;
  @Input() isOrderIdAndAllSelected: boolean = false;
  @Input() orderId: string ='';
  @Input() isCommentTitle?: boolean = false;
  @Input() inLineWithSubtitle: boolean = false;
  @Input() isCustomHeader:boolean = false;
  
 

  

  isMobileView: boolean = false;

  constructor(
    public iconRegistry: MatIconRegistry,
    public sanitizer: DomSanitizer,
    public helperService: HelperFunctionService
  ) {
    iconRegistry.addSvgIcon(
      "Arrow_Up",
      sanitizer.bypassSecurityTrustResourceUrl(
        "../../../../assets/v2-asset/round-minus-icon.svg"
      )
    );
    iconRegistry.addSvgIcon(
      "Arrow_Down",
      sanitizer.bypassSecurityTrustResourceUrl(
        "../../../../assets/v2-asset/round-plus-icon.svg"
      )
    );
  }

  ngOnInit() {
    this.detectMobileOrWebView();
  }

  //To check mobile responsive
  detectMobileOrWebView() {
    const mobileQuery = "(max-width: 767px)";
    const webQuery = "(min-width: 768px)";

    // Detect mobile view with a dynamic media query
    this.helperService.isViewportMatch(mobileQuery).subscribe((result) => {
      if (result.matches) {
        this.isMobileView = true;
      }
    });

    // Detect web view with a dynamic media query
    this.helperService.isViewportMatch(webQuery).subscribe((result) => {
      if (result.matches) {
        this.isMobileView = false;
      }
    });
  }
}
