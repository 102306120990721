import { Injectable, Input, signal } from "@angular/core";
import {
  BehaviorSubject,
  Observable,
  catchError,
  firstValueFrom,
  map,
  of,
} from "rxjs";

import { HttpParams } from "@angular/common/http";
import { MySitesServicesService } from "../../../mySitesServices/services/my-sites-services.service";
import { ProfileService } from "src/app/features/accounts/services/profile/profile-services.service";
import { ContractList,  ContractDetail} from "src/app/v2/models/contract-model";
import { UserProfile } from "src/app/models/profile-model";
import { MatDialog } from "@angular/material/dialog";
import { title } from "process";
import { ConfirmationPopupComponent } from "src/app/v2/shared/components/confirmation-popup/confirmation-popup.component";
import { ToastrService } from "ngx-toastr";
import { CalenderPopupComponent } from "src/app/v2/shared/components/calender-popup/calender-popup.component";
import { Data } from "src/app/models/zipcode";
import { AncillaryServices } from "src/app/v2/models/ancillary-services-model";
import { AssetLocation } from "src/app/v2/models/asset-location-model";
import { CaseDataOfSelectedAsset } from "src/app/v2/models/cases-model";
import { ChangeQuantityModel, SelectedLocationOfChangeQuantity } from "src/app/v2/models/selectedlocation-change-qty-model";

@Injectable({
  providedIn: "root",
})
export class OrderService {


  selectedLocationDataOfChangeQty = signal<SelectedLocationOfChangeQuantity | null>(null);
  selectedContractId = signal(""); 
 

  
  selectedContractData = signal<ContractList | null>(null);

  orderSummaryDetails = signal<ContractDetail | null>(null);

  caseDataOfSelectedAsset =signal<CaseDataOfSelectedAsset |null>(null);

  unselectedSiteAddresses=signal<AssetLocation[]>([]);

  allSelectedAssets =signal<AssetLocation[]>([]);



  // behaviorsubject for products
  public orderSummaryBehaviorSubject = new BehaviorSubject<ContractDetail | null>(
    null
  );
  // allproducts as observable
  orderSummary: Observable<ContractDetail | null> =
    this.orderSummaryBehaviorSubject.asObservable();
  private contractDataBehaviorSubject = new BehaviorSubject<ContractList | null>(
    null
  );
  ccount: Partial<UserProfile>;
  drawerState = signal<boolean>(false);
  isReduceQuantityState = signal<boolean>(false);
  isQuantitySingle = signal<boolean>(false);
  isCancelOrderFlow = signal<boolean>(false);
  orderManagementFlag = signal<string>('');


  



  contractData = signal<ContractList[] | null>(null);

  constructor(
    private api: MySitesServicesService,
    private profileService: ProfileService,
    private dialogBox : MatDialog,
    public toastr: ToastrService,
  ) {}


    // Method to update the signal
    setOrderSummary(order: ContractDetail): void {
      this.orderSummaryDetails.set(order);
    }

    setCaseDataOfSelectedAsset(caseData: CaseDataOfSelectedAsset): void {
      this.caseDataOfSelectedAsset.set(caseData);
    }

    setUnselectedSiteAddresses(assetData: AssetLocation[]): void {
      this.unselectedSiteAddresses.set(assetData);
    }
    
    setSelectedLocationDataOfChangequantity(selectedLocation:SelectedLocationOfChangeQuantity):void{
      this.selectedLocationDataOfChangeQty.set(selectedLocation);

    }

  getOrderById(id: String) {
    firstValueFrom(
      this.api
        .get(
          `accounts/${
            this.profileService.selectedAccount().accountId
          }/contracts/${id}`
        )
        .pipe(
          map((res) => {
            if (res) {
              console.log("Here is result-->", res)
              this.orderSummaryBehaviorSubject.next(res["data"]);
              // this.orderSummaryDetails.set(res["data"]);
              this.setOrderSummary(res["data"])
              return res["data"]
            }
          }),
          catchError((err) => {
            console.error(
              `error getting get order by id: ${JSON.stringify(err)}`
            );
            return of(null);
          })
        )
    );
  }

  getAllContract(filter: string, projectId?: string) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append("status", filter);
    if (projectId) {
      queryParams = queryParams.append("projectId", projectId);
    }
    return this.api
      .get(
        `accounts/${this.profileService.selectedAccount().accountId}/contracts`,
        {
          params: queryParams,
        }
      )
      .pipe(
        map((res) => {
          if (res["status"] === 1000) {
            this.contractData.set(res["data"].contracts);
            return res["data"].contracts;
          }
        }),
        catchError((err) => {
          console.error(`error getting contracts: ${JSON.stringify(err)}`);
          throw err;
        })
      );
  }
  
  //group by function for asset location
  groupBy(
    assetLocation: AssetLocation[],
    objectKey1: string,
    objectKey2: string
  ) {
    const groupedData = assetLocation.reduce((acc, assetLocation) => {
      const key1 = assetLocation[objectKey1] || "-";
      const key2 = assetLocation[objectKey2] || "-";

      // Group by key1
      acc[key1] = acc[key1] || {};

      // Group by key2 within each key2 group
      acc[key1][key2] = acc[key1][key2] || [];
      acc[key1][key2].push(assetLocation);

      return acc;
    }, {});

    console.log("grouped Data", groupedData);
    return groupedData;
  }
  // to return array of object keys
  objectKeys(object: any): string[] {
    return Object.keys(object);
  }
  cancelOrder(orderData: {}) {
    return this.api.post(`contracts/cancel`, orderData).pipe(
      map((res: {}) => {
        if (res["status"] === 1000) {
          return res;
        } else {
          return null;
        }
      }),
      catchError((err) => {
        console.error(
          `error getting while cancelling order: ${JSON.stringify(err)}`
        );
        throw err;
      })
    );
  }

  mergeSameAsset(assetLocation: AssetLocation[]) {
    const mergedData = assetLocation?.reduce(
      (result: AssetLocation[], currentObj: AssetLocation) => {
        const existingObj: AssetLocation | null =
          result?.find(
            (item: AssetLocation) =>
              item.assetName === currentObj.assetName &&
              item.serviceName === currentObj.serviceName &&
              item.endDate === currentObj.endDate
          ) || null;

        if (existingObj) {
          existingObj.quantity += currentObj.quantity;
        } else {
          result.push({ ...currentObj });
        }

        return result;
      },
      []
    );
    return mergedData;
  }

  editOrderQuantity(
    editOrderData: ChangeQuantityModel
  ): Observable<ChangeQuantityModel | null> {
    const quantityChange = {
      quantityChange: [editOrderData],
    };
    console.log(`request`, quantityChange);

    return this.api.post(`contracts/edit-quantity`, quantityChange).pipe(
      map((res) => {
        console.log(`res sucess`, res);
        if (res["status"] === 1000) {
          return res;
        }
      }),
      catchError((err) => {
        console.log(`error updating order quantity: ${JSON.stringify(err)}`);

        throw err;
      })
    );
  }

  setDrawerState(state: boolean) {
    console.log("setDrawerState", state);
    this.drawerState.set(state);
  }

  setReduceQuantityState(state: boolean) {
    console.log("setReduceQuantityState", state);
    this.isReduceQuantityState.set(state);
  }
  setCancelOrderFlowState(state:boolean){
    console.log("SetCanceOrderFlowState", state);
    this.isCancelOrderFlow.set(state);
  }
  setOrderManagementState(state:string){
    console.log("Which option is selected", state)
    this.orderManagementFlag.set(state);

  }

 
  getOrderCases(accountId: string, status: string, contractId: string) {
    return this.api
      .get(
        `accounts/${accountId}/contracts/${contractId}/cases?status=${status}`,
        {}
      )
      .pipe(
        map((res) => {
          if (res["status"] === 1000) {
            return res["data"];
          }
        }),
        catchError((err) => {
          console.error(`error requesting quote: ${JSON.stringify(err)}`);
          throw err;
        })
      );
  }

  getOrderClosedCases(
    accountId: string,
    status: string,
    contractId: string,
    startTime: string,
    endTime: string,
  ) {
    return this.api
      .get(
        `accounts/${accountId}/contracts/${contractId}/cases?status=${status}&startTime=${startTime}&endTime=${endTime}`,
        {}
      )
      .pipe(
        map((res) => {
          if (res["status"] === 1000) {
            return res["data"];
          }
        }),
        catchError((err) => {
          console.error(`error requesting quote: ${JSON.stringify(err)}`);
          throw err;
        })
      );
  }

    // fetch all products
    getPDF(quoteId: string, documentId: string) {
      return this.api
        .getPdfBlob(`quotes/${quoteId}/documents/${documentId}`)
        .pipe(
          map((res) => {
            if (res) {
              const mediaType = "application/pdf";
              const blob = new Blob([res], { type: mediaType });
              return blob;
            }
            return;
          }),
          catchError((err) => {
            console.error(`error getting quote PDF: ${JSON.stringify(err)}`);
            // throw err; >> use this to rethrow the error if that makes sense
            return of(null);
          })
        );
    }

    disableCancelButton(contracts : ContractList[]){
      const currentDate = new Date()
      currentDate.setHours(5); // Set the hour to 5
      currentDate.setMinutes(30); // Set the minutes to 30
      currentDate.setSeconds(0); //  reset the seconds to 0
      currentDate.setMilliseconds(0);
      const contractsList = contracts.map(list =>{
        if(list.status == "Pending Delivery"){
          const startDate = new Date(list.startDate)
          console.log("startDate",startDate)
          console.log("current date",currentDate)
          const deliveryDate = new Date(list.startDate)
          deliveryDate.setDate(deliveryDate.getDate() - 1);
          if(currentDate < deliveryDate || currentDate > startDate){
          
            list.isCancelButtonFlag = false
          }else{
            list.isCancelButtonFlag = true
          }
        }else{
          list.isCancelButtonFlag = false
        }
        return list
      })
      return contractsList
        }

        onCancelContract(contract : ContractList){
          console.log(contract)
          let action = ''
          let pickupDate = new Date(contract.endDate)
          if(contract.status == 'Pending Delivery'){
            action = 'cancel'
          }else if(contract.status == "Active"){
            if(pickupDate.getFullYear() == 2049){
              action = 'close'
            }else{
              action = 'change end date'
            }
          }

          this.dialogBox.open(ConfirmationPopupComponent,{
            minWidth: '20%',
            disableClose: true,
            data : {
              title: action == 'cancel'? 'Cancel Order' : action == "close"? "Close Order" : "Change End Date",
              description: `Are you sure you want to ${action == 'cancel'? action : action == "close"? action : "Change End Date of"} this order?`
          }
          }).afterClosed().subscribe((res)=>{
            console.log(res)
            if(action == 'cancel' && res == 'Yes'){
              this.cancelOrder({id: contract.contractId}).subscribe((result)=>{
                if(result){
                  this.toastr.success("Order Cancelled Successfully.");
                  return
                }
              })
            }else if(action != 'cancel' && res == 'Yes'){
              console.log("in change end date")
              this.dialogBox.open(CalenderPopupComponent,{
               
                disableClose: true,
                data: {
                  contractId: contract.contractId,
                  endDate: contract.endDate,
                  isShowCloseBtn: action == 'change end date'? false : true,
                  isShowCalendar: true,
                  stopNavigation: true,
                },
              })
            }
          })
    }    

    getAncillaryServiceNames(services: AncillaryServices[]): string {
      return services
        .slice(2) // Skip the first two elements
        .map(service => service.ancillaryServiceName) // Extract the names
        .join(', '); // Join them with commas
    }
     

    downloadFile(accountId: string, documentId: string) {
      return this.api
        .getPdfBlob(`accounts/${accountId}/documents/${documentId}?accountId=${accountId}`)
        .pipe(
          map((res) => {
            if (res) {
              const mediaType = "application/pdf";
              const blob = new Blob([res], { type: mediaType });
              return blob;
            }
            return;
          }),
          catchError((err) => {
            return of(null);
          })
        );
      }

      getImageForAttachment(title: string | undefined): string {
        if (!title) {
          return "../../../assets/img/icons/doc.svg"; 
        }
      
        const extension = title.split(".").pop(); 
        switch (extension) {
          case "pdf":
            return "../../../assets/img/icons/pdf-icon.svg";
          case "png":
            return "../../../assets/img/icons/png.svg";
          case "jpg":
          case "jpeg":
            return "../../../assets/img/icons/jpg.svg";
          case "doc":
            return "../../../assets/img/icons/doc.svg";
          case "docx":
            return "../../../assets/img/icons/docx.svg";
          default:
            return "../../../assets/img/icons/doc.svg"; 
        }
      }

      convertBlobToBase64(blob: Blob): Promise<string> {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onerror = reject;
          reader.onload = () => {
            const base64String = (reader.result as string).split(",")[1]; 
            resolve(base64String);
          };
          reader.readAsDataURL(blob);
        });
      }
    
    
       saveFile(blob: Blob, fileName: string): void {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      } 
    
  }