<!-- <mat-divider class="pb-3"></mat-divider> -->
<ng-scrollbar id="vertical-menu" pointerEventsMethod="scrollbar">
  <app-vertical-menu [menuItems]="menuItems" [menuParentId]="0"></app-vertical-menu>
  <div *ngIf="isMobile">
    <mat-divider class="custom-divider"></mat-divider>
  <app-mobile-menu-items [items]="mobileMenuItem"></app-mobile-menu-items>
  <mat-divider class="custom-divider"></mat-divider>
  <a class="logout-button" (click)="logout()">
  <span class="logout-text">Logout</span>
  <img src="assets/img/icons/log_out.svg" class="arrow-icon" alt="Arrow Icon">
  </a>
  </div>
</ng-scrollbar>


