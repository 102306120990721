import { Injectable } from "@angular/core";
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse } from "@angular/common/http";
import { Observable, catchError, finalize, map, throwError } from "rxjs";
import { LoadingService } from "src/app/shared/services/loading/loading.service";
import { ca } from "date-fns/locale";
import { ConfigService } from "../../services/config/config.service";
import { QuoteStateService } from "src/app/features/quotes/services/quote-state.service";

@Injectable()
export class GlobalLoaderInterceptor implements HttpInterceptor {
  activeRequests: number = 0;
  urlArray: string[] = ["create-quote", "documents","notifications"];

  constructor(
    private loadingService: LoadingService,
    private configService: ConfigService,
    private quoteStateService: QuoteStateService
  ) {}
  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const url = request.url;

    if ((request.url.includes("create-quote")) || request.url.includes("notifications") || request.url.includes("dashboard-details")){
      
      return next.handle(request);
    }

    if (request.headers.get("Authorization") || url.includes("zipcodes")) {
      // Increment activeRequests count and start loader
      if (
        this.activeRequests === 0 &&
        !this.isLastSegmentInArray(url, this.urlArray) &&
        this.isSecondLastSegmentInArray(url)
      ) {
        this.startLoader(url);
      }
      this.activeRequests++;
      return next.handle(request).pipe(
        map((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            this.activeRequests--;
            if (event["body"]?.status !== 1018 && this.activeRequests == 0) {
              this.loadingService.startLoading(false);
            }
          }
          return event;
        }),
        catchError((error: HttpErrorResponse) => {
          this.activeRequests--;
          if (this.activeRequests == 0) {
            this.loadingService.startLoading(false);
          }
          throw error;
        })
      );
    } else {
      return next.handle(request);
    }
  }

  isLastSegmentInArray(url: string, array: string[]) {
    const urlSegments = url.split("/");
    const lastSegment = urlSegments[urlSegments.length - 1];
    if(lastSegment == "create-quote"){
      return array.includes("create-quote") && (url.includes("v1"));
    }

    return array.includes(lastSegment) ;
  }
  isSecondLastSegmentInArray(url: string) {
    const urlSegments = url.split("/");
    const lastSegment = urlSegments[urlSegments.length - 2];

    return lastSegment !== "documents";
  }

  startLoader(url: string) {
    const urlSegments = url.split("/");
    const lastSegment = urlSegments[urlSegments.length - 1];
    if (!this.configService.getConfigProperty("QUOTE_HTML")) {
      if (lastSegment == "document") {
        this.loadingService.startLoading(true, "Generating Quote PDF", true);
        return;
      }
    }
    if (this.configService.getConfigProperty("QUOTE_HTML")) {
      if (lastSegment == "document") {
        this.loadingService.startLoading(false, "Generating Quote", true);
        return;
      }
      if (
        this.quoteStateService.getCurrentValue().currentStep === 2 ||
        this.quoteStateService.getCurrentValue().currentStep === 1 ||
        this.quoteStateService.getCurrentValue().currentStep === 0
      ) {
        if (url.includes("document")) {
          this.loadingService.startLoading(false);
          return;
        }
      }

      if (this.quoteStateService.getCurrentValue().currentStep === 5) {
        if (url.includes("document")) {
          this.loadingService.startLoading(true);
          return;
        }
      }
    }

    if (lastSegment == "update-quote") {
      this.loadingService.startLoading(true, "Saving Quote Details", true);
      return;
    }

    if (url.includes("quote-html")) {
      this.loadingService.startLoading(true, "Generating Quote", true);
      return;
    }
    this.loadingService.startLoading(true);
    return;
  }

  shouldBypassLoader(url: string): boolean {
    return this.urlArray.some((route) => url.includes(route));
  }
}
