import { Component } from "@angular/core";
import { AppSettings } from "src/app/app.settings";
import { Settings } from "../../../../app.settings.model";
import { AuthenticationService } from "src/app/shared/services/authentication/authentication.service";
import { appDisplayName } from "src/assets/config/constants";

@Component({
  selector: "app-easy-pay",
  templateUrl: "./easy-pay.component.html",
  styleUrls: ["./easy-pay.component.scss"],
})
export class EasyPayComponent {
  public settings: Settings;
  applicationTitle = appDisplayName;

  constructor(public appSettings: AppSettings, private auth: AuthenticationService) {
    this.settings = this.appSettings.settings;
  }
  userExist: boolean;

  ngOnInit() {
    localStorage.setItem("easypay", "true");
  }

  // click on get started button
  getStarted() {
    this.auth.login();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.settings.loadingSpinner = false;
    }, 300);
  }
}
