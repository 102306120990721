import { Injectable, signal } from "@angular/core";
import { appDisplayName } from "src/assets/config/constants";

@Injectable({
  providedIn: "root",
})
export class AppTitleService {
  applicationTitle = appDisplayName;
  constructor() {}
  appTitleSignal = signal(this.applicationTitle);

  setPageTitle(newTitle: string): void {
    this.appTitleSignal.set(newTitle);
  }
}
