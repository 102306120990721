<section class="popuo-wrapper position-relative">
  @if(popUpValues.topCloseButton){
  <mat-icon role="button" class="align-self-start position-absolute close-warning-icon cursor"
    (click)="closeDialog()">close</mat-icon>
  }
  <div class="popup-header p-2 pb-0 pt-3 d-flex justify-content-center align-items-center">
    <img [src]="popUpValues.headerIcon" [alt]="popUpValues.headerIconAltText" />
  </div>
  <div class="popup-body d-flex justify-content-center align-items-center p-3">
    <p class="text-center">
      {{ popUpValues.headerBodyText }}
    </p>
  </div>

  <div [ngClass]="{'popup-footer': (popUpValues.leftButtonText ||  popUpValues.rightButtonText)}"
    class=" px-2 pt-2 pb-3 d-flex flex-wrap justify-content-center align-items-center gap-2">
    @if(popUpValues.leftButtonText){
    <button mat-flat-button class="left" (click)="leftButtonClick()">{{ popUpValues.leftButtonText }}</button>
    }
    @if(popUpValues.rightButtonText){
    <button mat-flat-button class="right" (click)="rightButtonClick()">
      {{ popUpValues.rightButtonText }}
    </button>
    }
  </div>
</section>