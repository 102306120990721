import { CommonModule } from "@angular/common";
import { Component, Inject, Input } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
export interface IPopUP {
  headerIcon?: string;
  headerIconAltText?: string;
  headerBodyText?: string;
  leftButtonText?: string;
  rightButtonText?: string;
  topCloseButton?:boolean
}

@Component({
  selector: "app-account-found-popup",
  standalone: true,
  imports: [MatButtonModule,CommonModule,MatIconModule],
  templateUrl: "./common-warning-popup.component.html",
  styleUrl: "./common-warning-popup.component.scss",
})
export class CommonWarningPopupComponent {
  popUpValues: IPopUP = {
    headerIcon: "assets/v2-asset/reject-popup-alert.svg",
    headerIconAltText: "alert-triangle",
    headerBodyText:"",
    leftButtonText: "",
    rightButtonText: "",
    topCloseButton:false
  };
  constructor(
    private dialogRef: MatDialogRef<CommonWarningPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public popUpData: IPopUP
  ) {
    if(popUpData){
      console.log(popUpData);
      this.popUpValues = Object.assign({},this.popUpValues,popUpData);
    }
  }
  leftButtonClick() {
    this.dialogRef.close(true);
  }
  rightButtonClick() {
    this.dialogRef.close(false);
  }
  closeDialog(){
    this.dialogRef.close();
  }
}
