import { Component, HostListener, OnInit, ViewEncapsulation, effect } from "@angular/core";
import { FormControl } from "@angular/forms";
import { Router } from "@angular/router";
import { debounceTime, filter, firstValueFrom, mergeMap } from "rxjs";
import { ProfileService } from "src/app/features/accounts/services/profile/profile-services.service";
import { QuoteStateService } from "src/app/features/quotes/services/quote-state.service";
import { UserProfile } from "src/app/models/profile-model";
import { AuthenticationService } from "src/app/shared/services/authentication/authentication.service";
import { features, mapFeaturesToRoutes } from "src/assets/config/constants";
import { Platform } from "@ionic/angular";
import { EnhanceQuoteService } from "src/app/features/enhance-quotes/services/enhance-quote.service";
import { ConfigService } from "../../services/config/config.service";
@Component({
  selector: "app-user-menu",
  templateUrl: "./user-menu.component.html",
  styleUrls: ["./user-menu.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class UserMenuComponent implements OnInit {
  public userImage = "assets/img/users/user.jpg";
  userProfile: UserProfile = new UserProfile();
  serchFormControl: FormControl = new FormControl();
  accounts: Partial<UserProfile>[] = [];
  searchAccount: Partial<UserProfile>[] = [];
  features = features;
  mapFeaturesToRoutes = mapFeaturesToRoutes;
  includeInactive: boolean = false;
  enhanceQuoteUiFlag: boolean ;
  view2Flag : boolean;
  isNative: boolean = false;

  allMyussFeatures = mapFeaturesToRoutes;
  myussModules: Record<string,boolean> = {};
  isMobile: boolean = false;
  myussQuoteEnabled:boolean=true;

  constructor(
    public auth: AuthenticationService,
    public profileService: ProfileService,
    private quoteStateService: QuoteStateService,
    public router: Router,
    private platform: Platform,
    private enhanceQuoteService: EnhanceQuoteService,
    private config: ConfigService
  ) {
    this.isNative = this.platform.is("hybrid");
    effect(() => {
      const account = this.profileService.selectedAccount();   
      if(this.profileService.userProfile$.value.enforceRBAC){
        if(account.myussUserRole === null && !localStorage.getItem("signUpRequestId")){
          this.router.navigateByUrl("/restricted-access");
        }
      }       
      const isActive = account?.accountPaymentStatus === "ACTIVE" || !account?.accountPaymentStatus;
      this.myussQuoteEnabled = isActive && account?.myussModules?.['myussQuotesEnabled'] || false;
      localStorage.setItem("userId", `${account?.accountId}`);
      localStorage.setItem("accountNumber", `${account?.accountNumber}`);
      localStorage.setItem("firstName", `${account?.firstName}`);
      localStorage.setItem("accountName", `${account?.accountName}`);
      this.toggleIncludeInactive(this.includeInactive);
    });
    effect(()=>{
      this.enhanceQuoteUiFlag = this.enhanceQuoteService.enhanceQuoteUiSignal();
      this.navigateToEnhanceQuote();
    })
  }

  async ngOnInit() {
    if (localStorage.getItem('enhanceQuoteUiFlag')) {
      this.enhanceQuoteService.enhanceQuoteUiSignal.set(JSON.parse(localStorage.getItem('enhanceQuoteUiFlag') as string));
    }
    this.view2Flag = this.config.getConfigProperty("ENHANCE_UI_ENABLED");
    if (!this.profileService.userProfile$.value?.accountId) {
      await firstValueFrom(this.profileService.fetchProfile());
    }
    this.profileService.userProfile$.subscribe((profile) => {
      this.userProfile = profile;
      this.toggleIncludeInactive(this.includeInactive)
    });
    this.searchAccount = this.accounts;
    this.serchFormControl.valueChanges
      .pipe(debounceTime(300))
      .subscribe((value) => {
        this.accounts = this.searchAccount?.filter(
          (account) =>
            account.firstName
              ?.toLocaleLowerCase()
              ?.includes(value?.toLowerCase()) ||
            account.lastName
              ?.toLocaleLowerCase()
              .includes(value?.toLowerCase()) ||
            account.accountName
              ?.toLocaleLowerCase()
              .includes(value?.toLowerCase()) ||
            account.accountNumber
              ?.toLocaleLowerCase()
              .includes(value?.toLowerCase())
        );
      });

      this.isMobile =  window.innerWidth <= 960;

    // this.authError();
  }

  accountSelectionChange(account: Partial<UserProfile>) {


    if(this.profileService.userProfile$.value.accounts.every((acc) => acc.myussUserRole === null)) return;
    const selectedAccount = account.accountId;
  
    this.searchAccount = this.userProfile.accounts?.filter(
      (account) => account.accountId !== selectedAccount
    );
    localStorage.setItem("accountId", `${account.accountId}`);

    if (this.router.url.includes("quotes/quotecreation")) {
      this.quoteStateService.resetState();
    } else {
      const url = this.router.url.split("/")[1];
      this.myussModules = account.myussModules || {};

      if(account.myussUserRole === "Account Owner" || account.myussUserRole === "Account Admin"){
        this.allMyussFeatures.push({feature: "myussUsersEnabled", route: "users"});
        this.myussModules["myussUsersEnabled"] = true;         
      }
      
      if (
        !account.myussModules?.[this.features[url]] &&
        !url.includes("account/profile")
      ) {
        this.navigateToEnabledFeature(account);
      }
    }

    this.profileService.setSelectedAccount = account;
  
    localStorage.removeItem("recentlyViewedProjects");
    this.toggleIncludeInactive(this.includeInactive)
    this.serchFormControl.setValue("");

    if(this.router.url.includes("creation") && this.view2Flag){
      this.router.navigateByUrl('/home');
    }

  }

  getInitials(
    account: Partial<UserProfile> = this.profileService.selectedAccount()
  ) {
    const firstName = account.firstName || "";
    const lastName = account.lastName || "";
    return firstName?.charAt(0) + lastName?.charAt(0);
  }

  logout() {
    // this.auth.getUserName()
    this.auth.logout();
    localStorage.setItem("logoutEvent", "logout" + Math.random());
    const accountId = localStorage.getItem("accountId")||"";
    localStorage.clear();
    localStorage.setItem("accountId",accountId)
  }

  navigateToEdit() {
    this.router.navigateByUrl("/account/profile?action=edit");
  }

  // authError() {
  //   this.auth.error$
  //     .pipe(
  //       filter(
  //         (e) => e instanceof GenericError && e.error === "login_required"
  //       ),
  //       mergeMap(() => this.auth.loginWithRedirect())
  //     )
  //     .subscribe();
  // }

  capitalizeFirstLetter(str) {
    return str?.charAt(0).toUpperCase() + str?.slice(1).toLowerCase();
  }

  @HostListener("window:resize") onWindowResize(): void {
    this.isMobile =  window.innerWidth <= 960;
  }

  navigateToEnabledFeature(account: Partial<UserProfile>) {
    let foundFeature = false;
    this.myussModules = account.myussModules || {};

    if(account.myussUserRole === "Account Owner" || account.myussUserRole === "Account Admin"){
      this.allMyussFeatures.push({feature: "myussUsersEnabled", route: "users"});
      this.myussModules["myussUsersEnabled"] = true;  
    }
    for (const feature of this.allMyussFeatures) {
      if (this.myussModules?.[feature.feature]) {
        this.router.navigate([feature.route]);
        foundFeature = true;
        break;
      }
    }
    if (!foundFeature) {
      this.router.navigateByUrl("/restricted-access?modules=disabled");
    }
  }

  filterActive() {
    this.accounts = this.userProfile.accounts?.filter(
      (account) =>
        account.accountId !== this.profileService.selectedAccount().accountId &&
        (account.accountPaymentStatus === "ACTIVE" ||
          !account.accountPaymentStatus)
    );
    this.searchAccount = this.accounts;
  }
  includeInactiveAccounts() {
    this.accounts = this.userProfile.accounts?.filter(
      (account) =>
        account.accountId !== this.profileService.selectedAccount().accountId
    );
    this.searchAccount = this.accounts;

  }

  /**
   * Toggles the include inactive accounts
   */
  toggleIncludeInactive(checked:boolean) {
    this.serchFormControl.setValue("");
    this.includeInactive = checked;
    checked? this.includeInactiveAccounts() : this.filterActive();
  }

  toggleEnhanceQuotes(checked:boolean) {
    this.enhanceQuoteUiFlag = checked;
    this.enhanceQuoteService.enhanceQuoteUiSignal.set(checked);
    localStorage.setItem('enhanceQuoteUiFlag', JSON.stringify(this.enhanceQuoteService.enhanceQuoteUiSignal()));
    if(this.router.url.includes("creation") || this.router.url.includes("siteaddressvalidation")){
      if((this.enhanceQuoteService.enhanceQuoteUiSignal() && this.view2Flag)){
        this.router.navigateByUrl("quotes/v2");
      }else{
        this.router.navigateByUrl("quotes");
      }
    }else{
      this.navigateToEnhanceQuote();

    }
  }


  navigateToEnhanceQuote(){
    if(this.router.url=="/quotes" || this.router.url=="/quotes/v2"){
      if(this.enhanceQuoteService.enhanceQuoteUiSignal() && this.view2Flag){
        this.router.navigateByUrl("quotes/v2");
      }else{
        this.router.navigateByUrl("quotes");
      }    
    }
}
}
