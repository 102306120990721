import { NgClass } from "@angular/common";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";

@Component({
  selector: "app-card",
  standalone: true,
  imports: [MatIconModule, NgClass],
  templateUrl: "./card.component.html",
  styleUrl: "./card.component.scss",
})
export class CardComponent {
  // Enables the display of the footer section when set to true.
  @Input() footer: boolean = false;

  // Allows the card content to be toggled open or closed when set to true.
  @Input() allowCollapse: boolean = false;

  // Sets the initial state of the card. When true, the card is expanded by default.
  @Input() isExpanded: boolean = true;

  // Controls whether the card-collapse-content is shown when the card is collapsed. If true, the collapsed content will be visible.
  @Input() showWhenCollapsed: boolean = true;

  // Controls whether to show the collapse icon. If allowCollapse is false, the icon will not be shown unless explicitly enabled. If allowCollapse is true, the collapse icon will always be shown.
  @Input() showCollapseIcon: boolean = false;

  // To change the color of collpase icon (more_vert)
  @Input() collapseIconColor: string = "#A4A7AE";

  // To change the background color
  @Input() backgroundColor: string = "#FFFFFF";

  @Input () borderColor : string = "#D5D7DA"

  @Output() CollpaseEvent = new EventEmitter<boolean>();

  @Input() isSegmentedBorder: boolean = false;

  toggleAccordian() {
    if (this.allowCollapse) {
      this.isExpanded = !this.isExpanded;
      this.CollpaseEvent.emit(this.isExpanded)
    }
  }


}
