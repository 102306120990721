import { BrowserModule } from "@angular/platform-browser";
import { APP_INITIALIZER, NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { OverlayContainer } from "@angular/cdk/overlay";
import { CustomOverlayContainer } from "./theme/utils/custom-overlay-container";

import { NgScrollbarModule } from "ngx-scrollbar";
import { CalendarModule, DateAdapter } from "angular-calendar";
import { adapterFactory } from "angular-calendar/date-adapters/date-fns";

import { SharedModule } from "./shared/shared.module";
import { PipesModule } from "./theme/pipes/pipes.module";
import { appRoutes } from "./app.routing";

import { AppSettings } from "./app.settings";
import { AppComponent } from "./app.component";

import {
  AuthClientConfig,
  AuthHttpInterceptor,
  AuthModule,
} from "@auth0/auth0-angular";
import {
  HTTP_INTERCEPTORS,
  HttpBackend,
  provideHttpClient,
  withInterceptorsFromDi,
} from "@angular/common/http";

import { ResetpopupComponent } from "./shared/components/dialogbox/resetpopup/resetpopup.component";
import { ToastrModule } from "ngx-toastr";
import { ZippopupComponent } from "./shared/components/dialogbox/zippopup/zippopup.component";

import { RejectpopupComponent } from "./shared/components/dialogbox/reject-popup/reject-popup.component";
import { ConfigService } from "./shared/services/config/config.service";

// Import the library

import { ConfirmationPopupComponent } from "./shared/components/dialogbox/confirmation-popup/confirmation-popup.component";

// firebase
import {
  ScreenTrackingService,
  UserTrackingService,
} from "@angular/fire/analytics";
import { LimitExceedComponent } from "./shared/components/dialogbox/limit-exceed/limit-exceed.component";
import { MAT_DATE_LOCALE } from "@angular/material/core";
import { CreateAccountWithoutAuthComponent } from "./features/accounts/components/create-account-without-auth/create-account-without-auth.component";
import { httpInterceptorProviders } from "./shared/http-interceptors";
import { RouterModule } from "@angular/router";
import { LandingModule } from "./features/landing/landing.module";
import { AddLocationPopupComponent } from "./shared/components/dialogbox/add-location-popup/add-location-popup.component";
import { VaultService } from "./shared/services/vault/vault.service";
import { ScanModule } from "./features/scan/scan.module";
import { FlexLayoutModule } from "@ngbracket/ngx-layout";
import { InitialUrlService } from "./shared/services/initial-url/initial-url.service";

import { TrackingPopupComponent } from "./features/tracking-popup/tracking-popup/tracking-popup.component";
import { IonicModule } from "@ionic/angular";
import { MyUSSModule } from "./features/myuss/myuss.module";
import { MySiteServicesModule } from "./v2/features/mySitesServices/my-sites-services.module";

const appInitFactory =
  (
    vaultService: VaultService,
    configService: ConfigService
  ): (() => Promise<void>) =>
  async () => {
    await vaultService.init();
    configService.load();
  };

export function captureInitialUrl(initialUrlService: InitialUrlService) {
  return () => {
    const url = window.location.pathname + window.location.search;
    initialUrlService.setInitialUrl(url);
  };
}

@NgModule({
  declarations: [
    AppComponent,
    RejectpopupComponent,
    ConfirmationPopupComponent,
    LimitExceedComponent,
    CreateAccountWithoutAuthComponent,
    AddLocationPopupComponent,
    TrackingPopupComponent,
  ],
  bootstrap: [AppComponent],
  imports: [
    IonicModule.forRoot(),
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    SharedModule,
    PipesModule,
    RouterModule.forRoot(appRoutes),
    ToastrModule.forRoot({
      timeOut: 3500,
      positionClass: "toast-bottom-center",
      preventDuplicates: true,
    }),
    // AuthModule.forRoot(),
    LandingModule,
    MyUSSModule,
    MySiteServicesModule,
    ScanModule,

    FlexLayoutModule,
  ],
  providers: [
    AppSettings,
    { provide: OverlayContainer, useClass: CustomOverlayContainer },
    {
      provide: APP_INITIALIZER,
      useFactory: captureInitialUrl,
      deps: [InitialUrlService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory:
        (
          vaultService: VaultService,
          configService: ConfigService
        ): (() => Promise<void>) =>
        async () => {
          await vaultService.init();
          await configService.load();
        },
      deps: [VaultService, ConfigService, HttpBackend],
      multi: true,
    },
    // { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    ScreenTrackingService,
    UserTrackingService,
    httpInterceptorProviders,
    { provide: MAT_DATE_LOCALE, useValue: "en-US" },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
