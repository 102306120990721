import { Component, effect, HostListener, signal, ViewChild } from "@angular/core";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { ExpansionPanelComponent } from "src/app/v2/shared/components/expansion-panel/expansion-panel.component";
import { EasyPayService } from "../../services/easy-pay.service";
import { ProfileService } from "src/app/features/accounts/services/profile/profile-services.service";
import {
  confirmEasyPayRequestPayload,
  easyPayStateModel,
  PaymentMethod,
} from "src/app/v2/models/easy-pay-details-model";
import { MatIconModule } from "@angular/material/icon";
import { CommonModule } from "@angular/common";
import {
  Stripe,
  StripeElements,
  loadStripe,
  StripeElementsOptionsClientSecret,
} from "@stripe/stripe-js";
import { PaymentCardComponent } from "src/app/v2/shared/components/payment-card/payment-card.component";
import { CustomStepperComponent } from "src/app/v2/shared/components/custom-stepper/custom-stepper.component";
import { ToastrService } from "ngx-toastr";
import { OrdersComponent } from "../../../orders/components/orders/orders.component";
import { ContractList } from "src/app/v2/models/contract-model";
import { CardComponent } from "src/app/v2/shared/components/card/card.component";
import { SelectionModel } from "@angular/cdk/collections";
import { OrderService } from "../../../orders/services/order/order.service";
import { MatPaginator, MatPaginatorModule, PageEvent } from "@angular/material/paginator";
import { MatDialog } from "@angular/material/dialog";
import { PromotionDialogComponent } from "../promotion-dialog/promotion-dialog.component";
import { LinkCardComponent } from "../../../home/components/link-card/link-card.component";
import { links } from "src/assets/config/constants";
import { Router } from "@angular/router";
import { LoadingService } from "src/app/shared/services/loading/loading.service";

@Component({
  selector: "app-easy-pay",
  standalone: true,
  imports: [
    ExpansionPanelComponent,
    MatCheckboxModule,
    MatIconModule,
    CommonModule,
    PaymentCardComponent,
    CustomStepperComponent,
    OrdersComponent,
    CardComponent,
    MatPaginatorModule,
    LinkCardComponent
  ],
  templateUrl: "./easy-pay.component.html",
  styleUrl: "./easy-pay.component.scss",
})
export class EasyPayComponent {
  stripeCustomerId;
  cardIcon = "../../../../../assets/img/card_icon/";
  savedDefaultPayments: PaymentMethod[] = [];
  clientSecret: string;
  stripe: Stripe;
  stripeElement: StripeElements;
  options: StripeElementsOptionsClientSecret;
  paymentMethod: string = "";
  links:Record<string,string>[] = [];
  //STEPS FOR STEPPER
  steps: string[] = ["Payment Method", "Select Order", "Confirm"];
  //STEP WISE TITLES
  titles: string[] = ["Easy Pay Setup", "Apply To Order", "Confirm Easy Pay"];
  
  selection = new SelectionModel<ContractList>(true, []);

  currentStep = signal<number>(0);
  selectedCard: PaymentMethod | undefined;
  selectedOrders: ContractList[];
  isMobile: boolean = false;
  orderList: ContractList[];
  paymentMethodIcon = "../../../../assets/v2-asset/payment-icons/";
  pageSize = 3;
  paginatorState: Map<string, { pageIndex: number }> = new Map();
  noOrdersAvailable:boolean=false;
  @ViewChild('contractsListPaginator') paginator:MatPaginator

  constructor(
    private easyPayService: EasyPayService,
    private profileService: ProfileService,
    private toastr: ToastrService,
    private orderService: OrderService,
    private dialog:MatDialog,
    private router:Router,
    private loadingService: LoadingService
  ) {
    effect(
      () => {
        if(this.currentStep()==0 && this.stripeCustomerId){
          this.loadStripeWithSetupIntent(this.stripeCustomerId);
        }
      },
      { allowSignalWrites: true }
    );
  }

  ngOnInit() {
    // reqBody is the request body to get the stripe customer id
    const reqBody = {
      accountName: this.profileService.selectedAccount().accountName,
      email: this.profileService.selectedAccount().email,
      accountNumber: this.profileService.selectedAccount().accountNumber,
    };
    // get the stripe customer id from the server
    this.easyPayService.getCustomerId(reqBody).subscribe((resp) => {
      if (resp) {
        this.stripeCustomerId = resp.stripeCustomerId;
        this.getSavedCards(this.stripeCustomerId);
        this.loadStripeWithSetupIntent(this.stripeCustomerId); // load strip add new card
      }
    });

    this.resize();
    this.links=links;
  }

  @HostListener("window:resize", ["$event"])
  resize() {
    if (window.innerWidth <= 768) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  }

  //get all saved new
  getSavedCards(accountId) {
    this.easyPayService.fetchCards(accountId).subscribe((res) => {
      this.savedDefaultPayments = res;
    });
  }

  //Load stripe add payment ui 
  async loadStripeWithSetupIntent(customer_id: string) {
    this.loadingService.startLoading(true)
    const clientSecret = await this.easyPayService.getPaymentIntent(
      customer_id
    );
    this.clientSecret = clientSecret;
    const stripe = await loadStripe(this.easyPayService.stripePublishableKey);
    if (stripe) {
      this.stripe = stripe;
      this.options = {
        clientSecret: clientSecret,
      };
      this.stripeElement = this.stripe.elements(this.options);
      const paymentElement = this.stripeElement.create("payment");
      paymentElement.mount("#payment-element");
      this.loadingService.startLoading(false);
    }else
    this.loadingService.startLoading(false);
  }
  //To save the card
  submitSetup() {
    this.stripeElement.submit().then(async (result) => {
      this.loadingService.startLoading(true);
      if (result.error) {
      this.loadingService.startLoading(false);
        return;
      }
      const elements = this.stripeElement;
      const { error } = await this.stripe.confirmSetup({
        //`Elements` instance that was used to create the Payment Element
        elements,
        redirect: "if_required",
      });
      if (error) {
        this.loadingService.startLoading(false);
        console.log(error);
        this.loadStripeWithSetupIntent(this.stripeCustomerId).then(() => {});
      } else {
        this.easyPayService
          .retrivePayementIntent(this.stripe, this.clientSecret)
          .then((payment_method: any) => {
            this.paymentMethod = payment_method;
            this.getSavedCards(this.stripeCustomerId)
            this.loadStripeWithSetupIntent(this.stripeCustomerId);
            this.loadingService.startLoading(false);
          });
      }
    });
  }

  //Get selected card
  getSelectedCard($event) {
    this.selectedCard = $event;
  }

  //Next click
  nextClick() {
    if (this.selectedCard) {
      if (this.currentStep() == 0) {
        this.currentStep.set(this.currentStep()+1);
      } else if (this.currentStep() == 1 && this.selectedOrders?.length) {
        this.currentStep.set(this.currentStep()+1);
      }else{
        this.toastr.error("Select at least one order to update or add a payment method.")
      }
    } else {
      this.toastr.error("Please select active card");
    }
    this.paginator?.firstPage();
    this.scrollToTopInView();
  }

  //On back click chnge step to previous step
  backClick() {
    this.currentStep.set(this.currentStep()-1);
    if(this.currentStep()==0){
      this.selection.clear();
      this.selectedOrders=[]
    }
    this.paginator?.firstPage();
    this.scrollToTopInView();
  }

  //Get array of selected orders from table / card
  selectedRows(event) {
    this.selectedOrders = event;
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection?.selected?.length;
    const numRows = this.orderList.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.orderList);
    this.selectedOrders = this.selection.selected;
  }

  updateSelection(row) {
    this.selection.toggle(row);
    this.selectedOrders = this.selection.selected;
  }

  // Get all orders to handle in mobile view cards
  handlerReceivedOrders(event) {
    this.orderList = event;
    this.orderList = this.orderList?.sort((a, b) =>
      b.name.localeCompare(a.name)
  );
  this.noOrdersAvailable=false;
    if(!this.orderList?.length){
      this.noOrdersAvailable=true;
    }
  }

  // Get paginated order cards for mobile view
  getPaginatedCards() {
    let allCards:ContractList[];
    if (this.currentStep() === 2) {
      allCards = this.selectedOrders;
    } else {
      allCards = this.orderList;
    }
    const paginatorStateEntry = Array.from(this.paginatorState.values())[0];
    const pageIndex = paginatorStateEntry?.pageIndex || 0;
    const startIndex = pageIndex * this.pageSize;
    return allCards?.slice(startIndex, startIndex + this.pageSize);
  }

  //Handle page change event
  onInnerPageChange(K: string, event: PageEvent) {
    this.paginatorState?.set(K, { pageIndex: event.pageIndex });
  }

  //Confirm and Upadate payment method
  confirmAndUpadtePaymentMethod() {
    if (this.selectedOrders.length && this.selectedCard) {
      const reqBody: confirmEasyPayRequestPayload = {
        contractIds: this.selectedOrders.map((order) => order.contractId),
        paymentMethodId: this.selectedCard?.paymentMethodId,
      };

      this.easyPayService.updatePaymentMethod(reqBody).subscribe((res) => {
        if (res) {
          this.openDialog();
          this.currentStep.set(this.currentStep()+1);
        }
      });
    }
  }

  //Open thank you dialog
  openDialog(){
    this.dialog.open(PromotionDialogComponent,{minWidth:"60vw",disableClose:true},).afterClosed().subscribe((res)=>{
      this.router.navigate([res])
    })
  }

  //scroll to top

  scrollToTopInView(){
    let element= document.querySelector("#easypay-stepper");
    element?.scrollIntoView({
      block:"end",
      behavior:"smooth",
      inline:"nearest"
    });
  }
  ngOnDestroy() {
    this.currentStep.set(0);
  }
}
