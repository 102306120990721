import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { perQuoteUnitLimit, phoneNumber } from "src/assets/config/constants";

@Component({
  selector: "app-edit-order-limit-exceed",
  templateUrl: "./edit-order-limit-exceed.component.html",
  styleUrls: ["./edit-order-limit-exceed.component.scss"],
})
export class EditOrderLimitExceedComponent {
  phoneNumber: string;
  perQuoteUnitLimit : number;
  addUnitLimit:number;
  
   
  constructor(private dialogref: MatDialogRef<EditOrderLimitExceedComponent>,  @Inject(MAT_DIALOG_DATA) public data: any) {}
  ngOnInit() {
    console.log("data", this.data);
    this.phoneNumber = phoneNumber;
    this.perQuoteUnitLimit = perQuoteUnitLimit
    this.addUnitLimit = this.data?.addUnitLimit
   
  }

  onClose() {
    this.dialogref.close();
  }
}
