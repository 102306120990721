import { Component, HostListener } from "@angular/core";
import { appDisplayName, phoneNumber } from "src/assets/config/constants";
import { ProfileService } from "src/app/features/accounts/services/profile/profile-services.service";
import { AccountService } from "src/app/features/accounts/services/account/account.service";
import { MatIconModule } from "@angular/material/icon";
import { CommonModule } from "@angular/common";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-promotion-dialog",
  standalone: true,
  imports: [MatIconModule, CommonModule],
  templateUrl: "./promotion-dialog.component.html",
  styleUrl: "./promotion-dialog.component.scss",
})
export class PromotionDialogComponent {
  flags: Record<string, boolean>;
  outstandingFlags: boolean = false;
  phoneNumber: string;
  applicationTitle = appDisplayName;
  isMobile: boolean;
  constructor(
    private profileService: ProfileService,
    private accountService: AccountService,
    private dialogRef: MatDialogRef<PromotionDialogComponent>
  ) {}

  async ngOnInit() {
    this.flags = await this.profileService.getAllMyussFlags();
    this.getAccountDetails();
    this.phoneNumber = phoneNumber;
    this.resize();
  }

  @HostListener("window:resize", ["$event"])
  resize() {
    if (window.innerWidth <= 768) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  }

  getAccountDetails() {
    this.accountService
      .getAccountDetails(
        this.profileService.selectedAccount().accountId as string
      )
      .then((res) => {
        if (res.outstandingBalance != null && res.outstandingBalance > 0) {
          this.outstandingFlags = true;
        } else {
          this.outstandingFlags = false;
        }
      });
  }

  onCall() {
    window.location.href = "tel:" + phoneNumber;
  }
  closeDialog(route: string) {
    this.dialogRef.close(route);
  }
}
