import {
  Component,
  OnInit,
  Input,
  ViewEncapsulation,
  effect,
} from "@angular/core";
import { AppSettings } from "../../../app.settings";
import { Settings } from "../../../app.settings.model";
import { MenuService } from "../../services/menu.service";
import { ProfileService } from "src/app/features/accounts/services/profile/profile-services.service";
import { Menu } from "../menu/menu.model";
import { AuthenticationService } from "src/app/shared/services/authentication/authentication.service";
import { ConfigService } from "src/app/shared/services/config/config.service";
import { Platform } from "@ionic/angular";
import { EnhanceQuoteService } from "src/app/features/enhance-quotes/services/enhance-quote.service";
import { verticalMenuItems } from "../menu/menu";
import { BreadcrumbService } from "src/app/shared/services/breadcrumbs/breadcrumb.service";
import { MySitesServicesService } from "src/app/v2/features/mySitesServices/services/my-sites-services.service";


@Component({
  selector: "app-vertical-menu",
  templateUrl: "./vertical-menu.component.html",
  styleUrls: ["./vertical-menu.component.scss"],
  encapsulation: ViewEncapsulation.None,
  providers: [MenuService],
})
export class VerticalMenuComponent implements OnInit {
  @Input("menuItems") menuItems: Menu[];
  @Input("menuParentId") menuParentId: number;
  parentMenu: Array<Menu>;
  projectEnabled: boolean;
  public settings: Settings;
  isMobile:boolean;
  constructor(
    public appSettings: AppSettings,
    public menuService: MenuService,
    private profileSerivce: ProfileService,
    private auth: AuthenticationService,
    private configService: ConfigService,
    private platform:Platform,
    private enhanceQuoteService: EnhanceQuoteService,
    private breadcrumbService: BreadcrumbService ,
    private mySiteSrvicesService:MySitesServicesService
  ) {
    this.settings = this.appSettings.settings;
    effect(() => {
      const account = this.profileSerivce.selectedAccount();
      this.enhanceQuoteService.enhanceQuoteUiSignal();  
      this.menuItems=verticalMenuItems;
      this.filterMenu();
    });
  }

  ngOnInit() {    
    this.projectEnabled =
      this.configService.getConfigProperty("PROJECTS_ENABLED");

    if (window.innerWidth <= 960) {
      this.isMobile = true;
    }
  }

  onClick(menuId: number) {
    console.log("menuId", menuId);
    this.menuService.toggleMenuItem(menuId);
    this.menuService.closeOtherSubMenus(this.menuItems, menuId);
    this.breadcrumbService.setMenuNavigation(true)
    
    if(this.isMobile){
      this.mySiteSrvicesService.setToggleMenuSignal(false);
    }

  }

  /**
   * filter menu by account level features
   */
  async filterMenu() {
    const isAuthenticated = this.auth.isAuthenticated();
    const storedValue = localStorage.getItem('enhanceQuoteUiFlag');
    const enhanceQuoteUiFlagValue = storedValue === 'true'; // Convert string back to boolean


    if (!isAuthenticated) return;
    const accountFeatures = await this.profileSerivce.getAllMyussFlags();
    this.parentMenu = this.menuItems?.filter((menu: Menu) => {
      let menuCondition: boolean;
      if (this.projectEnabled && accountFeatures["myussProjectsEnabled"]) {
        menuCondition =
          menu.parentId == this.menuParentId &&
          accountFeatures[menu.flagName] 
        return menuCondition;
      }
      menuCondition =
        menu.parentId == this.menuParentId &&
        accountFeatures[menu.flagName] &&
        !["Projects"].includes(menu.title);
      return menuCondition;
    });
    if(this.platform.is('hybrid')){
        this.parentMenu.push(new Menu (9, 'About App', 'about-app', null, 'version', null, false, 0,"myussUsersEnabled",'user','This is Users. Create and manage user permissions.'))
    }
  }
}
