import { Component, effect, HostListener } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { LinkCardComponent } from "../link-card/link-card.component";

import {
  ITileInfo,
  TileComponent,
} from "src/app/v2/shared/components/tile/tile.component";
import { HomeService } from "../../services/home.service";
import { ProfileService } from "src/app/features/accounts/services/profile/profile-services.service";
import { Router } from "@angular/router";
import { NgClass } from "@angular/common";
import { links } from "src/assets/config/constants";

@Component({
  selector: "app-home",
  standalone: true,
  imports: [MatButtonModule, LinkCardComponent, TileComponent,NgClass],
  templateUrl: "./home.component.html",
  styleUrl: "./home.component.scss",
})
export class HomeComponent {
  myussFlags: Record<string, boolean>;
  isMobileWidth:boolean = false;
  constructor(
    private homeService: HomeService,
    private profileService: ProfileService,
    public router: Router,
  ) {
    effect(async () => {
      const account = this.profileService.selectedAccount();
      this.myussFlags = await this.profileService.getAllMyussFlags();
      this.myussFlags.newQuote=this.myussFlags.myussQuotesEnabled &&
      !this.profileService.isAccountInActive() &&
      !this.profileService.isReadOnly();
      if (account.accountId) {
        this.getDashboardCount(`${account.accountId}`);
      }
    });
  }
  ngOnInit() {
    if(window.innerWidth <= 768){
      this.isMobileWidth = true;
    }else{
      this.isMobileWidth = false;
    }
    this.links=links
  }

  @HostListener("window:resize") onWindowResize(): void {
      if(window.innerWidth <= 600){
        this.isMobileWidth = true;
      }else{
        this.isMobileWidth = false;
      }
    }

  dashboardCards: ITileInfo[] = [
    {
      title: "Projects",
      icon: "assets/v2-asset/projects-home-Icon.svg",
      number: 0,
      description: "Your Current Active Quotes <br> Manage Your Projects",
      mobileDescription: "Active Projects <br> Manage Your Projects",
      flag: "myussProjectsEnabled",
      route:"projects"
    },
    {
      title: "Orders",
      icon: "assets/v2-asset/orders-home-Icon.svg",
      number: 0,
      description: "Manage Your Orders <br> Locations - Move Units",
      mobileDescription: "Manage Your Orders <br> Locations and Units",
      flag: "myussOrdersEnabled",
      route:"orders"
    },
    {
      title: "Quotes",
      icon: "assets/v2-asset/quotes-home-icon.svg",
      number: 0,
      description: "Your Current Active Quotes <br> Manage Your Quotes",
      mobileDescription: "Manage Your Quotes <br> Active and Pending",
      flag: "myussQuotesEnabled",
      route:"quotes"
    },
    {
      title: "New Quote",
      icon: "assets/v2-asset/newQuote-home-icon.svg",
      description: "Project Quote<br>Stand Alone Quote",
      mobileDescription: "Project Quote <br> Stand Alone Quote",
      flag: "newQuote",
      route:"quotes/quote-creation/new-quote"
    },
    {
      title: "Cases",
      number: 0,
      icon: "assets/v2-asset/cases-home-icon.svg",
      description: "Current Open Cases<br>Manage Your Cases",
      mobileDescription: "Manage Your Quotes <br> Active and Pending",
      flag: "myussCasesEnabled",
      route:"cases"
    },
    {
      title: "Users",
      number: 0,
      icon: "assets/v2-asset/users-home-icon.svg",
      description: "Active Users<br>Manage Permissions",
      mobileDescription: "Manage Your Quotes <br> Active and Pending",
      flag: "myussUsersEnabled",
       route:"users"
    },
    {
      title: "Billing",
      icon: "assets/v2-asset/billing-home-icon.svg",
      description:
        "View Current and Past Invoices <br> Make one-time payments<br> Open billing inquiries",
        mobileDescription: "View Invoices <br> Make one-time payment <br> Open billing inquiries",
      flag: "myussBillingEnabled",
      route:"billing"
      
    },
    {
      title: "Easy Pay",
      icon: "assets/v2-asset/easyPay-home-icon.svg",
      description: "Manage Automatic Payment on <br> Single or Multiple Orders",
      mobileDescription: "Manage Auto Pay on  <br> Single or Multiple Orders",
      flag: "myussEasyPayEnabled",
      route:"easypay"
      
    },
  ];

  allowedCards:ITileInfo[]=[];

  links:Record<string,string>[] = [];

  getDashboardCount(accountId: string) {
    this.homeService
      .getDashboardCount(accountId)
      .subscribe((dasboardCount: Record<string, number>) => {
        if (!dasboardCount) {
          return;
        }

        for (const card of this.dashboardCards) {
          if ("number" in card) {
            const cardTitle = card.title.slice(0, -1).toLowerCase() + "Count";
            card.number = dasboardCount[cardTitle];
          }
        }
        this.allowedCards = this.dashboardCards.filter((card) => this.myussFlags[card.flag])
          
        

      });
  }

  navigateTo(endPoint: string) {
    if(endPoint){
      this.router.navigate([endPoint]);
    }
  }
}
