import { Injectable } from "@angular/core";
import { MySitesServicesService } from "../../mySitesServices/services/my-sites-services.service";
import { catchError, map, of } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class HomeService {
  constructor(private api: MySitesServicesService) {}

  getDashboardCount(accountId:string){
    return this.api.get(`accounts/${accountId}/dashboard-details`).pipe(
      map((res) => {
        if (res["status"] === 1000) {
          
          return res["data"] ;
        }
        return null;
      }),
      catchError((err) => {
        console.error(`error getting all quotes : ${JSON.stringify(err)}`);
        return of(null);
      })
    );
  }
}
