import { Component, effect } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { LinkCardComponent } from "../link-card/link-card.component";

import {
  ITileInfo,
  TileComponent,
} from "src/app/v2/shared/components/tile/tile.component";
import { HomeService } from "../../services/home.service";
import { ProfileService } from "src/app/features/accounts/services/profile/profile-services.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-home",
  standalone: true,
  imports: [MatButtonModule, LinkCardComponent, TileComponent],
  templateUrl: "./home.component.html",
  styleUrl: "./home.component.scss",
})
export class HomeComponent {
  myussFlags: Record<string, boolean>;
  constructor(
    private homeService: HomeService,
    private profileService: ProfileService,
    public router: Router,
  ) {
    effect(async () => {
      console.log("dasboardCounteffect");
      const account = this.profileService.selectedAccount();
      this.myussFlags = await this.profileService.getAllMyussFlags();
      this.myussFlags.newQuote=this.myussFlags.myussQuotesEnabled &&
      !this.profileService.isAccountInActive() &&
      !this.profileService.isReadOnly();
      if (account.accountId) {
        this.getDashboardCount(`${account.accountId}`);
      }
    });
  }

  dashboardCards: ITileInfo[] = [
    {
      title: "Projects",
      icon: "assets/v2-asset/card_icon_project.svg",
      number: 0,
      description: "Your Current Active Quotes <br> Manage Your Projects",
      flag: "myussProjectsEnabled",
      route:"projects"
    },
    {
      title: "Orders",
      icon: "assets/v2-asset/card_icon_order.svg",
      number: 0,
      description: "Manage Your Orders <br> Locations - Move Units",
      flag: "myussOrdersEnabled",
      route:"orders"
    },
    {
      title: "Quotes",
      icon: "assets/v2-asset/card_icon_quotes.svg",
      number: 0,
      description: "Your Current Active Quotes <br> Manage Your Quotes",
      flag: "myussQuotesEnabled",
      route:"quotes"
    },
    {
      title: "New Quote",
      icon: "assets/v2-asset/card_icon_new_quote.svg",
      description: "Project Quote<br>Stand Alone Quote",
      flag: "newQuote",
      route:"quotes"
    },
    {
      title: "Cases",
      number: 0,
      icon: "assets/v2-asset/card_icon_cases.svg",
      description: "Current Open Cases<br>Manage Your Cases",
      flag: "myussCasesEnabled",
      route:"cases"
    },
    {
      title: "Users",
      number: 0,
      icon: "assets/v2-asset/card_icon_users.svg",
      description: "Active Users<br>Manage Permissions",
      flag: "myussUsersEnabled",
       route:"users"
    },
    {
      title: "Billing",
      icon: "assets/v2-asset/card_icon_billing.svg",
      description:
        "View Current and Past Invoices <br> Make one-time payments<br> Open billing inquiries",
      flag: "myussBillingEnabled",
      route:"billing"
      
    },
    {
      title: "Easy Pay",
      icon: "assets/v2-asset/card_icon_easy_pay.svg",
      description: "Manage Automatic Payment on Single or Multiple Orders",
      flag: "myussEasyPayEnabled",
      route:"easypay"
      
    },
  ];

  links = [
    {
      icon: "assets/v2-asset/what_we_offer.svg",
      title: "What We Offer",
      navigationLink: "https://www.unitedsiteservices.com/what-we-offer/",
    },
    {
      icon: "assets/v2-asset/branch_icon.svg",
      title: "Find a Branch Near Your Area",
      navigationLink: "https://www.unitedsiteservices.com/locations/",
    },
    {
      icon: "assets/v2-asset/support_icon.svg",
      title: "Customer Support",
      navigationLink: "https://www.unitedsiteservices.com/customer-support/",
    },
    {
      icon: "assets/v2-asset/uss_icon.svg",
      title: "About Us",
      navigationLink: "https://www.unitedsiteservices.com/about/",
    },
  ];

  getDashboardCount(accountId: string) {
    this.homeService
      .getDashboardCount(accountId)
      .subscribe((dasboardCount: Record<string, number>) => {
        if (!dasboardCount) {
          return;
        }

        for (const card of this.dashboardCards) {
          if ("number" in card) {
            const cardTitle = card.title.slice(0, -1).toLowerCase() + "Count";
            card.number = dasboardCount[cardTitle];
          }
        }
      });
  }

  navigateTo(endPoint: string) {
    console.log("endpoint",endPoint)
    if(endPoint){
      this.router.navigate([endPoint]);
    }
  }
}
