import { CommonModule } from "@angular/common";
import { Component, ContentChild, ElementRef, input, Input, output, signal } from "@angular/core";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatIconModule } from "@angular/material/icon";
import { MatPaginatorModule, PageEvent } from "@angular/material/paginator";
import { MatRadioModule } from "@angular/material/radio";
import { PaymentMethod } from "src/app/v2/models/easy-pay-details-model";
import { EventEmitter } from "stream";

@Component({
  selector: "app-payment-card",
  standalone: true,
  imports: [MatIconModule, MatCheckboxModule,MatPaginatorModule,MatRadioModule,CommonModule],
  templateUrl: "./payment-card.component.html",
  styleUrl: "./payment-card.component.scss",
})
export class PaymentCardComponent {
  //For all saved cards got from api
  @Input(({ required: true }))savedDefaultPayments: (PaymentMethod | undefined)[];
  //Enable or disable auto pay
  @Input() autoPay:boolean=false;
  //Set the items per page in pagination
  @Input()pageSize = 6;
  //Output used to emit selected card
  selctedCard = output<PaymentMethod | undefined>();
  //To only show the card without selection option
  @Input() disableSelection:boolean=false;
  //Marked card
  @Input() markedCard:PaymentMethod | undefined;
  //Hide pagination
  @Input() hidePagination:boolean=false;
  //BackGround color to individual card
  @Input() backgroundColor:string;
  //projected Content selector for header content
  @ContentChild('cutomHeader') content: ElementRef;

  
  hasContent:boolean=false;
  cardIcon = "../../../../../assets/img/card_icon/";
  paginatorState: Map<string, { pageIndex: number }> = new Map();


  constructor(){}

  ngAfterContentInit(){
    this.hasContent = (this.content?.nativeElement?.innerHTML.trim()?true:false);
  }
  ngOnChanges(){
    if(!this.markedCard){      
      this.markedCard= this.savedDefaultPayments[0];
      if(!this.markedCard?.card?.expired){
      this.selctedCard.emit(this.markedCard);
      }
    }
  }

  // Get paginated cards
  getPaginatedCards() {
    // Fetch all saved cards.
    const allCards = this.savedDefaultPayments;
    // Retrieve the pagination state.
    const paginatorStateEntry = Array.from(this.paginatorState.values())[0];
    // Retrieve the current page index from paginator state, default to 0 if undefined.
    const pageIndex = paginatorStateEntry?.pageIndex || 0;
    // Calculate the starting index for the current page.
    const startIndex = pageIndex * this.pageSize;
    // Extract and return the subset of cards for the current page.
    return allCards?.slice(startIndex, startIndex + this.pageSize);
  }

  //Handle page change event
  onInnerPageChange(K: string, event: PageEvent) {
    this.paginatorState.set(K, { pageIndex: event.pageIndex });
  }
  //Emit selected card 
  emitSlectedCard(card:PaymentMethod | undefined){
    if(card){
    this.selctedCard.emit(card);
    }
  }

  ngOnDestroy(){
    //clear payment cards
    this.savedDefaultPayments= [];
    this.markedCard=undefined;
  }

}
