<div class="title-div">
    <h3 class="p-2">{{confirmationText.title}}</h3>
</div>
<div class="mt-3">
    <p class="px-2">{{confirmationText.description}}</p>
</div>
<mat-divider class="my-2 mt-3"></mat-divider>
<div class="button-div p-2">
    <button class="button-size button-color" mat-flat-button mat-dialog-close>No</button>
    <button
      class="button-size"
      mat-flat-button
      (click)="onYes()"
    >
      Yes
    </button>
  </div>