import { Injectable, signal } from "@angular/core";
import { BehaviorSubject } from "rxjs";
interface Loader {
  value: boolean;
  message: string;
  isMessage: boolean;
}
@Injectable({
  providedIn: "root",
})
export class LoadingService {

  loaderFlag = signal<{flag:boolean,setLoader:boolean}>({flag:false,setLoader:false})

  private lodingBehaviorSubject = new BehaviorSubject<Loader>({
    value: false,
    message: "",
    isMessage: false,
  });
  loader = this.lodingBehaviorSubject.asObservable();

  constructor() {}

  setLoader(value: boolean, message: string = "", isMessage: boolean = false) {
    return
    // this.lodingBehaviorSubject.next({ value, message, isMessage });
  }
  startLoading(value: boolean, message: string = "", isMessage: boolean = false) {
    this.lodingBehaviorSubject.next({ value, message, isMessage });
  }
}
