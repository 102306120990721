<section class="container" [ngClass]="{ 'easy-pay-container': isMobile }">
  <!-- PAGE TITLE AND BACK BUTTON FOR MOBILE -->
  @if(isMobile){
  <div class="d-flex justify-content-between align-items-center mb-2">
    <p class="fs-20 font-weight-500">Easy Pay</p>
    @if(currentStep()>0){
    <button
      mat-flat-button
      class="mobile-back-button cursor-pointer"
      (click)="backClick()"
    >
      <img
        src="../../../../assets/v2-asset/back-icon.svg"
        alt="navigate back to previous screen"
      />
    </button>
    }
  </div>
  }
  <!-- STEPPER DIV WITH TITLE AND BACK BUTTON -->
  <div id="easypay-stepper">
    <app-custom-stepper [steps]="steps" [currentStep]="currentStep()">
      <div text-content class="d-flex flex-column">
        <p class="fs-18">{{ titles[currentStep()] }}</p>
        <p class="fs-14">Only available for recurring payments</p>
      </div>
      @if(currentStep()> 0 && !isMobile){
        <button
        btn-content
        (click)="backClick()"
        class="back-button d-flex justify-content-start align-items-center cursor-pointer"
      >
        <img
          src="../../../../assets/v2-asset/back-icon.svg"
          alt="navigate back to previous screen"
        /><span>Back</span>
      </button>
      }
    </app-custom-stepper>
  </div>

  @if(!(currentStep()>0 && noOrdersAvailable)){
  <!-- SELECTED PAYMENT CARD ON STEP 3 -->
  @if(currentStep()===2 && isMobile) {
  <ng-container [ngTemplateOutlet]="slectedPaymentCard"></ng-container>
  }

  <!-- ADD PAYMENT METHOD AND ORDER LSIT DIV -->
  <div class="add-payment-method-container">
    <!-- EXPANSION PANEL OF ADD PAYMENT METHOD -->
    @if(currentStep()===0){ @if(!isMobile){
    <app-expansion-panel
      [title]="'Add Payment Method'"
      [subtitle]="''"
      [isNoOfUnitSelected]="false"
      [isOrderIdAndAllSelected]="false"
      [isOpened]="true"
      [inLineWithSubtitle]="false"
      [isIcon]="false"
    >
      <div main-content>
        <div id="payment-element"></div>
        <div class="d-flex justify-content-end" id="save-buttton">
          <button class="cursor-pointer"
            id="card-details-save"
            mat-flat-button
            (click)="submitSetup()"
          >
            Save
          </button>
        </div>
      </div>
    </app-expansion-panel>
    }@else{
    <div class="add-payment-method-mobile border-radius-8">
      <div class="payment-method-header-mobile fs-18 font-weight-500">
        Add Payment Method
      </div>
      <div class="payment-method-content-mobile">
        <div id="payment-element"></div>
        <div class="d-flex justify-content-start ">
          <button
          class="cursor-pointer"
            id="card-details-save"
            mat-flat-button
            (click)="submitSetup()"
          >
            Save
          </button>
        </div>
      </div>
    </div>
    } }
    <!-- ORDER LIST -->
    @if(currentStep()!=0){
    <div [ngClass]="{ 'd-none': isMobile }">
      @if(currentStep()===2 && !isMobile) {
      <div class="selected-card-div">
        <ng-container [ngTemplateOutlet]="slectedPaymentCard"></ng-container>
      </div>
      }
      <app-orders
        [showOnlyTable]="true"
        [showCheckbox]="currentStep() == 1 ? true : false"
        (selectedRows)="selectedRows($event)"
        (ordersData)="handlerReceivedOrders($event)"
        [noTopBorder]="true"
        [noBottomBorder]="currentStep() == 2 ? true : false"
        [sharedOrders]="currentStep() == 2 ? selectedOrders : []"
      ></app-orders>
      @if(currentStep()==2){
      <div class="tnc-container px-3 pb-3">
        <ng-container [ngTemplateOutlet]="tnc"></ng-container>
      </div>
      }
    </div>

    <!-- ORDER CARDS FOR MOBILE -->
    <div
      class="card-list-wrapper border-radius-8"
      [ngClass]="{ 'd-none': !isMobile }"
    >
      <div
        class="d-flex justify-content-between align-items-center card-top-header"
      >
        <p class="fs-16 font-weight-500">Order ID</p>
        @if(currentStep()===1) {<mat-checkbox
          (change)="$event ? toggleAllRows() : null"
          [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()"
        >
          Select All</mat-checkbox
        >}
      </div>
      @for(order of getPaginatedCards();track $index){
      <app-card
        [footer]="false"
        [allowCollapse]="false"
        [isSegmentedBorder]="true"
      >
        <div
          card-header
          class="d-flex justify-content-between align-items-center card-header-padding"
        >
          <p class="fs-16 font-weight-600">{{ order.name }}</p>
          @if(currentStep()===1){
          <mat-checkbox
            (click)="$event.stopPropagation()"
            (change)="$event ? updateSelection(order) : null"
            [checked]="selection.isSelected(order)"
          >
          </mat-checkbox
          >}
        </div>
        <div card-content class="row g-2 p-2">
          <!-- STATUS -->
          <div class="col-6">Status</div>
          <div class="col-6">
            @if(order.easyPayDetails?.expired){

            <div class="expired justify-content-center width">
              Expired
            </div>
            }@else{
            <p
              class="status-tag"
              [ngClass]="{
                'active-status': order.isAutoPay === true,
                'other-status': order.isAutoPay === false
              }"
            >
              {{ order.isAutoPay ? "Active" : "Inactive" }}
            </p>
            }
          </div>
          <!-- PAYMENT METHOD -->
          <div class="col-6">Payment Method</div>
          <div class="col-6">
            <div class="d-flex justify-content-start align-items-center">
              <p *ngIf="order.easyPayDetails?.cardNo" class="pr-2">
                <span
                  *ngIf="order.easyPayMode == 'card'"
                  class="p-1 pl-1"
                  fxLayoutAlign="start center"
                >
                  <img 
                    [src]="
                      paymentMethodIcon +
                      order.easyPayDetails?.cardBrand +
                      '.png'
                    "
                    alt="Payment method card logo"
                    *ngIf="
                      [
                        'jcb',
                        'discover',
                        'diners',
                        'visa',
                        'mastercard',
                        'amex'
                      ].includes(order.easyPayDetails?.cardBrand!)
                    "
                    height="20px"
                    class="pl-1 card-logo"
                  />
                  <img 
                    *ngIf="
                      ![
                        'jcb',
                        'discover',
                        'diners',
                        'visa',
                        'mastercard',
                        'amex'
                      ].includes(order.easyPayDetails?.cardBrand!)
                    "
                    class="pl-1 card-logo"
                    src="../../../../assets/v2-asset/payment-icons/visa.png"
                    aria-hidden="true"
                  />
                </span>

                <img class="card-logo"
                  src="../../../../assets/v2-asset/bank-icon.svg"
                  aria-hidden="true"
                  *ngIf="order.easyPayMode == 'bank'"
                  aria-label="Payment method bank logo"
                  role="img"
                  aria-hidden="false"
                />
              </p>
              <div class="d-flex justify-content-center align-items-center">
                <p *ngIf="order.easyPayDetails?.cardNo" class="gray-600">
                  ****
                </p>
                <p class="cardNo_div gray-600">
                  {{
                    order.easyPayDetails?.cardNo
                      ? order.easyPayDetails?.cardNo
                      : "Manual"
                  }}
                </p>
              </div>
            </div>
          </div>
          <!-- RECURRING AMOUNT -->
          <div class="col-6">Recurring Amount</div>
          <div class="col-6">
            {{ order.recurringSubtotal | currency : "USD" : "symbol" }}
          </div>
          <!-- INVOICE DUE DATE -->
          <div class="col-6">Invoice Due Date</div>
          <div class="col-6">{{ order.nextInvoiceDate }}</div>
          <!-- SITE ADDRESS -->
          <div class="col-6">Site Address</div>
          <div class="col-6 text-truncate">{{ order.shippingAddress }}</div>
        </div>
      </app-card>

      }
      <mat-paginator
        class="paginator-class"
        #contractsListPaginator
        [length]="currentStep()==1?orderList?.length:selectedOrders?.length"
        [pageSize]="pageSize"
        (page)="onInnerPageChange('', $event)"
        [showFirstLastButtons]="true"
        [hidePageSize]="isMobile ? true : false"
      >
      </mat-paginator>
      @if(currentStep()==2){
      <div
        class="tnc-container px-2 pb-3"
        [ngClass]="{ 'no-bottom-border': isMobile }"
      >
        <ng-container [ngTemplateOutlet]="tnc"></ng-container>
      </div>
      }
    </div>
    }
  </div>

  <!-- SAVED DEFAULT PAYMENT CARD LIST -->
  @if(currentStep() < 2){
  <div class="saved-payments">
    <app-expansion-panel
      [title]="'Saved Default Payment'"
      [subtitle]="''"
      [isNoOfUnitSelected]="false"
      [isOrderIdAndAllSelected]="false"
      [isOpened]="currentStep() == 0"
      [inLineWithSubtitle]="false"
      [isIcon]="false"
    >
      <div main-content class="row gy-2 payment-card-wrapper">
        <app-payment-card
          [savedDefaultPayments]="savedDefaultPayments"
          [pageSize]="6"
          (selctedCard)="getSelectedCard($event)"
          [markedCard]="selectedCard"
          [autoPay]="false"
        ></app-payment-card>
      </div>
    </app-expansion-panel>
  </div>
  }

  <!-- BOTTOM BUTTONS  -->
  <div id="bottom-button" class="d-flex justify-content-end w-100">
    @if(currentStep()===2){
    <button
      [ngClass]="{ 'w-100': isMobile }"
      (click)="backClick()"
      class="button-wrapper-mobile d-flex justify-content-center align-items-center cursor-pointer cancel-button"
    >
      Cancel
    </button>
    <button
      [ngClass]="{ 'w-100': isMobile }"
      (click)="confirmAndUpadtePaymentMethod()"
      class="button-wrapper-mobile d-flex justify-content-center align-items-center cursor-pointer confirm-button"
    >
      Confirm
    </button>
    }@else{
    <button
      [ngClass]="{ 'w-100': isMobile }"
      (click)="nextClick()"
      class="button-wrapper-mobile d-flex justify-content-center align-items-center cursor-pointer next-button"
    >
      <span>Next</span>
      <img
        src="assets/v2-asset/arrow-narrow-right.svg"
        alt="navigate back to previous screen"
      />
    </button>
    }
  </div>
}@else{
  <div class="w-100 mx-auto d-flex justify-content-center mt-2">
  <div class="d-flex flex-column justify-content-center align-items-center border-radius-8 white_bg no-orders-wrapper p-3">
    <img _ngcontent-ng-c299967898="" alt="" src="assets/img/icons/Icon - No Active Orders.png">
    <h2 class="text-center">You have no Active orders yet!</h2>
    <p class="text-center">The selected payment method will be used for future orders.</p>
  </div>
</div>
}
</section>

<!-- Showing slected payment card -->
<ng-template #slectedPaymentCard>
  <div class="selected-payment-card">
    <app-payment-card
      [savedDefaultPayments]="[selectedCard]"
      [disableSelection]="true"
      [hidePagination]="true"
      [backgroundColor]="'#FFFFFF'"
    >
      <p #cutomHeader class="font-weight-600 fs-16" custom-header-text>
        Confirm Payment For Orders Selected
      </p>
    </app-payment-card>
  </div>
</ng-template>

<!-- Showing tnc  -->
<ng-template #tnc>
  <div class="tnc-wrapper">
    <p class="fs-14 mb-2">
      <sup>*</sup>Recurring amount listed here may differ from Invoices due to
      taxes, order adjustments, or other changes. The amount shown on invoices
      takes precedence and this amount is for estimating only.
    </p>
    <p class="fs-14">
      By clicking “confirm” you agree to have United Site Services charge the
      selected payment method for the full invoice amount on the due date of the
      invoice in accordance with the terms and conditions described in your
      order documents.
    </p>
  </div>
</ng-template>

<!-- BOTTOM CARDS FOR MOBILE -->
@if(isMobile){
<div class="row g-3 bottom-cards">
  @for(link of links; track link){
  <div class="col-12 col-xxl-3 col-xl-3 col-md-6 col-lg-4 col-sm-6">
    <app-link-card
      [title]="link.title"
      [navigationLink]="link.navigationLink"
      [icon]="link.icon"
    ></app-link-card>
  </div>
  }
</div>
}



