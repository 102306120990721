import { CommonModule } from "@angular/common";
import { Component, Input, input } from "@angular/core";
export interface ITileInfo {
  title: string;
  icon: string;
  number?: number;
  description: string;
  flag:string
  route:string
}
@Component({
  selector: "app-tile",
  standalone: true,
  imports: [CommonModule],
  templateUrl: "./tile.component.html",
  styleUrl: "./tile.component.scss",
})
export class TileComponent {
  @Input({required:true})tileInfo:ITileInfo
}
