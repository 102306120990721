<section class="p-3 main-wrapper">
    <div class="d-flex justify-content-end">
    <button class="close-outlined-btn cursor-pointer just" (click)="closeDialog('/home')">
        <img src="assets/v2-asset/close-icon.svg" alt="close drawer" />
      </button>
    </div>
  <div
    class="d-flex flex-column justify-content-center align-items-center text-content-wrapper"
  >
    <img
      class="mb-3 check-icon"
      src="assets/v2-asset/thank-you-promotion.svg"
      alt=""
    />
    <h3 class="fs-24 font-weight-600 mb-4">Thank You!</h3>
    <p class="fs-14 font-weight-400">
      Thank you for choosing MyUSS Easy Pay to manage your payments.
    </p>
    <p class="fs-14 font-weight-400">
      At United Site Services we are committed to making your site services
      Easy, Safe, and Clean.
    </p>
  </div>

  @if(outstandingFlags){
  <div class="w-100 warning-message-wrapper d-flex  border-radius-8 mt-3" [ngClass]="{'flex-column':isMobile,'flex-row':!isMobile}">
    <div class="w-25 d-flex justify-content-center align-items-center icon-div" [ngClass]="{'w-100':isMobile,'w-25':!isMobile}">
      <img src="assets/img/icons/red-alert-triangle.svg" alt="" />
    </div>
    <div class="w-75 d-flex justify-content-between flex-column" [ngClass]="{'w-100':isMobile,'w-75':!isMobile}">
      <h3 class="pt-3 px-3">
        EasyPay updates are effective as of the next invoice of each order.
      </h3>
      <p class="px-3 red-text">
        Your account indicates that there is a past due balance which will need
        to be paid in one of the following ways:
      </p>
      <div class="d-flex justify-content-between flex-column flex-md-row p-3">
        <div>
            @if(flags?.myussBillingEnabled){
          <div class="d-flex">
            <mat-icon class="icon-scale me-1">fiber_manual_record</mat-icon>
            <p>
              Visit the
              <a
                _ngcontent-ng-c3592445271=""
                routerlink="/billing"
                class="text-decoration-none"
                ng-reflect-router-link="/billing"
                href="/billing"
                >Billing</a
              >
              page to make a one-time payment.
            </p>
          </div>
        }
          <div class="d-flex">
            <mat-icon class="icon-scale me-1">fiber_manual_record</mat-icon>
            <p>
              Call
              <a href="tel:1-888-320-1861" class="phone-number"
                >1-888-320-1861
              </a>
              to arrange for payment with a representative.
            </p>
          </div>
        </div>
        <div class="d-flex  ms-2" [ngClass]="{'align-self-center mt-3':isMobile,'align-self-end':!isMobile}">
          @if(flags?.myussBillingEnabled){
          <button mat-flat-button class="pay_Bill cursor-pointer" (click)="closeDialog('/billing')">
            Pay Bill
          </button>
          } @if(!flags?.myussBillingEnabled){
          <button mat-flat-button class="pay_Bill cursor-pointer" (click)="onCall()">
            Call Now
          </button>
          }
        </div>
      </div>
    </div>
  </div>
}
</section>
