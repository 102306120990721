import { Routes } from "@angular/router";
import { authGuard } from "src/app/guards/auth/auth.guard";
import { MySitesServicesWrapperComponent } from "../mySitesServices/components/my-sites-services-wrapper/my-sites-services-wrapper.component";
import { HomeComponent } from "../home/components/home/home.component";
import { isRegisteredGuard } from "src/app/guards/is-registered.guard";
import { myussEnabledGuard } from "src/app/guards/myussEnabled/myuss-enabled.guard";
import { QuotesComponent } from "../quotes/components/quotes/quotes.component";
import { OrdersComponent } from "../orders/components/orders/orders.component";
import { BillingComponent } from "../billing/components/billing/billing.component";
import { ProjectsComponent } from "../projects/components/projects/projects.component";
import { EasyPayComponent } from "../easyPay/components/easy-pay/easy-pay.component";
import { CasesComponent } from "../cases/components/cases/cases.component";
import { UsersComponent } from "../users/components/users/users.component";
import { featureGuard } from "src/app/guards/feature/feature.guard";
import { UserAccountInfoComponent } from "../accounts/components/user-account-info/user-account-info.component";
import { LinkAccountComponent } from "../accounts/components/link-account/link-account.component";
import { CompanyDetailsComponent } from "../accounts/components/company-details/company-details.component";
import { ApprovalPendingComponent } from "../accounts/components/approval-pending/approval-pending.component";
import { MultipleAccountScreenComponent } from "../accounts/components/multiple-account-screen/multiple-account-screen.component";
import { userInfo } from "os";

export const mySiteServicesRoutes: Routes = [
  {
    path: "",
    component: MySitesServicesWrapperComponent,
    canActivate: [authGuard ,myussEnabledGuard],
    children: [
      {
        path: "",
        pathMatch: "full",
        redirectTo: "home",
      },
      {
        path: "home",
        canActivate: [isRegisteredGuard],
        data: { feature: "myussHomeEnabled",},
        component: HomeComponent,
      },
      {
        path: "quotes",
        canActivate: [isRegisteredGuard],
        data: { feature: "myussQuotesEnabled",},
        component: QuotesComponent,
      },
     
      {
        path: "orders",
        canActivate: [isRegisteredGuard, featureGuard],
        data: { feature: "myussOrdersEnabled",Breadcrumb: 'Quote List'
        },
        loadChildren: () =>
          import("../orders/orders.routes").then((m) => m.ordersRoute),
      },
      {
        path: "cases",
        canActivate: [isRegisteredGuard],
        data: { feature: "myussCasesEnabled",},
        component: CasesComponent,
      },
      {
        path: "billing",
        canActivate: [isRegisteredGuard],
        data: { feature: "myussBillingEnabled",},
        component: BillingComponent,
      },
      {
        path: "projects",
        canActivate: [isRegisteredGuard],
        data: { feature: "myussHomeEnabled",},
        component: ProjectsComponent,
      },
      {
        path: "easypay",
        canActivate: [isRegisteredGuard],
        data: { feature: "myussEasyPayEnabled",},
        component: EasyPayComponent,
      },
      {
        path: "users",
        canActivate: [isRegisteredGuard],
        data:{role: ["Account Owner", "Account Admin"],Breadcrumb: 'Users List'
        },
        component: UsersComponent,
      },
    ],
  },
  {
    path: "account",
    canActivate: [authGuard,isRegisteredGuard],
    data: {Breadcrumb: 'Account'},
    loadChildren: () =>
      import('../accounts/accounts.routes').then((m) => m.accountsRoutes),
  }
];
