<div class="wrapper d-flex justify-content-between align-items-center">
    <!-- @if(textContent){ -->
    <div class="text align-self-start">
        <ng-content select="[text-content]"></ng-content>
    </div>
    <!-- } -->

    @if (!mobileWidth) {
    <div class="stepper">
        @for (step of steps; track $index; let i=$index;let last=$last) {
        <div class="step">
            <!-- Connecting Line (except first step) -->
            @if (i > 0) {
            <div class="line" [ngClass]="{ filled: i <= currentStep + 1,'last-line':last }"></div>
            }

            <!-- Step Circle -->
            <div class="circle" [ngClass]="{
          active: i === currentStep ,
          completed: i < currentStep 
        }">
                @if (i
                < currentStep ) { <img class="tick" src="assets/v2-asset/tick.svg" />
                }

                @if (i === currentStep ) {
                <span>
                    <div class="current-step">
                        <div class="inner">
                            <div class="center"></div>
                        </div>
                    </div>
                </span>
                }
                @if(i>currentStep){
                <div class="center-incomplete"></div>
                }

            </div>

            <!-- Label Below -->
            <p class="label" [ngClass]="{ 'active-label': i <= currentStep  }">
                {{ step }}
            </p>
        </div>
        }
    </div>
    } @else {
    <div class="stepper">
        <div class="step">
            <div class="line filled"></div>
            <div class="circle active">
                @if (steps.length== (currentStep )) {
                <img class="tick" src="assets/v2-asset/tick.svg" />
                }
                @if ((currentStep ) <steps.length) { <span>
                    <div class="current-step">
                        <div class="inner">
                            <div class="center"></div>
                        </div>
                    </div>
                    </span>
                    }
            </div>

            <p class="label active-label">{{ steps[currentStep] }}</p>
            <div class="step-counter d-flex justify-content-center align-items-center">
                <p>{{ currentStep +1 }} &#47; {{ steps.length }}</p>
            </div>
        </div>
    </div>
    } @if (!mobileWidth) {
    <div class="btn align-self-start">
        <ng-content select="[btn-content]"></ng-content>
    </div>
    }
</div>