import { PushNotificationService } from "src/app/features/myuss/services/push-notification/push-notification.service";
import { Component } from "@angular/core";
import { AppNotifications } from "src/app/models/notification-model";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-push-notification",
  templateUrl: "./push-notification.component.html",
  styleUrls: ["./push-notification.component.scss"],
})
export class PushNotificationComponent {
  notificationList: AppNotifications[];

  constructor(
    private pushNotificationService: PushNotificationService,
    private toaster: ToastrService
  ) {}

  ngOnInit() {
    this.getPushNotifications();
  }

  getPushNotifications() {
    this.pushNotificationService.getPushNotifications().subscribe({
      next: (res) => {
        if (res) {
          this.notificationList = res.sort((a, b) => {
            return (
              new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
            );
          });
        }
      },
      error: (error) => {
        this.toaster.error("Error fetching notifications:", error);
      },
    });
  }
}
