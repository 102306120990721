import { Component, Input, ViewChild, effect } from "@angular/core";
import { GoogleMap, MapInfoWindow, MapMarker } from "@angular/google-maps";
import { HomeService } from "src/app/features/home/services/home.service";
import { Router } from "@angular/router";
import { ProfileService } from "src/app/features/accounts/services/profile/profile-services.service";
import { GoogleMapsModule } from "@angular/google-maps";
import { OrderService } from "src/app/v2/features/orders/services/order/order.service";
import { CommonModule } from "@angular/common";
import { FlexLayoutModule } from "@ngbracket/ngx-layout";
import { ContractList } from "src/app/v2/models/contract-model";
import { LatLng } from "src/app/v2/models/lat-long-model";

@Component({
  selector: "app-contract-location",
  standalone: true,
  imports: [GoogleMapsModule, MapMarker, CommonModule, FlexLayoutModule],
  templateUrl: "./contract-location.component.html",
  styleUrl: "./contract-location.component.scss",
})
export class ContractLocationComponent {
  @ViewChild(GoogleMap, { static: false }) map: GoogleMap;
  @ViewChild(MapInfoWindow) infoWindow: MapInfoWindow | undefined;
  @Input() showLabel: boolean = true;
  latlngbounds = new google.maps.LatLngBounds();
  infoWindowPosition: google.maps.LatLngLiteral | LatLng;
  shippingAddress: String | undefined;
  zipcode: String | undefined;
  contractList: ContractList[];
  orderAndContractIds: { orderId: string; contractId: string }[] = [];
  userId: string;
  @Input() siteAddress: string = "";
  contarctDataList: ContractList[] | undefined;

  constructor(
    private homeService: HomeService,
    private router: Router,
    private orderService: OrderService,
    private profileService: ProfileService
  ) {
    effect(()=>{
      if (this.siteAddress) {
        const obj= this.orderService.contractData()?.find(
          (site:ContractList) =>
            site.shippingAddress.toLowerCase() ===
            this.siteAddress.toLowerCase()
        );
        const dataList: ContractList[] = [];
        if(obj){
          dataList.push(obj);
        }
        this.contractList = dataList;
        this.contarctDataList = this.orderService.contractData() ?? [];
      } else {
        this.contractList = this.orderService.contractData() ?? [];
        this.contarctDataList = this.contractList;
      }
      if (this.contractList) {
        let bounds = this.getBounds(this.contractList);
        if (
          this.map &&
          this.map.googleMap &&
          (bounds.east || bounds.west || bounds.north || bounds.south)
        ) {
          this.map.googleMap.fitBounds(this.getBounds(this.contractList));
        }
      }
    })
  }

  options: google.maps.MapOptions = {
    center: { lat: 37.0902, lng: -95.7129 }, 
    zoom: 4,
    maxZoom: 18,
    clickableIcons: true,
  };

  async ngOnInit() {
    this.options.styles = await this.homeService.loadMapStyle();
  }

  

  /* Bounds for map (Determine the center point of the map view based on the locations of pins)*/
  getBounds(markers: ContractList[]) {
    let north;
    let south;
    let east;
    let west;
    if (markers?.length > 0) {
      for (const marker of markers) {
        if (marker.shippingAddressLat || marker.shippingAddressLng) {
          north =
            north !== undefined
              ? Math.max(north, marker.shippingAddressLat)
              : marker.shippingAddressLat;
          south =
            south !== undefined
              ? Math.min(south, marker.shippingAddressLat)
              : marker.shippingAddressLat;
          east =
            east !== undefined
              ? Math.max(east, marker.shippingAddressLng)
              : marker.shippingAddressLng;
          west =
            west !== undefined
              ? Math.min(west, marker.shippingAddressLng)
              : marker.shippingAddressLng;
        }
      }
    }
    const bounds = { north, south, east, west };
    return bounds;
  }

  openInfoWindow(marker: MapMarker, markerData: ContractList) {
    const ordersWithSameAddress = this.contarctDataList?.filter(
      (order) =>
        order.shippingAddressLat === markerData.shippingAddressLat &&
        order.shippingAddressLng === markerData.shippingAddressLng
    );

    if (this.infoWindow != undefined && ordersWithSameAddress) {
      this.infoWindow.open(marker);
      this.shippingAddress = markerData.shippingAddress;

      // Combine order IDs and contract IDs into a single list
      this.orderAndContractIds = ordersWithSameAddress.map((order) => ({
        orderId: order.name,
        contractId: order.contractId,
      }));
    }
  }
  openOrderSummary(contractId: string): void {
    this.router.navigate(["orders/ordersummary", contractId]);

  }

  close() {
    if (this.infoWindow) {
      this.infoWindow.close();
    }
  }
}
