import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import {MatDividerModule} from '@angular/material/divider';
import {MatButtonModule} from '@angular/material/button';



export class   ConfirmationModel {
  title: string;
  description: string

  }
@Component({
  selector: 'app-confirmation-popup',
  standalone: true,
  imports: [MatDividerModule,MatButtonModule,MatDialogModule],
  templateUrl: './confirmation-popup.component.html',
  styleUrl: './confirmation-popup.component.scss'
})

export class ConfirmationPopupComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public confirmationText: ConfirmationModel,
    private dialogRef: MatDialogRef<ConfirmationPopupComponent>
  ){}
  ngOnInit(){
    console.log(this.confirmationText)
  }

  onYes(){
   this.dialogRef.close("Yes")
  }

}