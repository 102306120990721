<section class="p-2">
  <div *ngIf="notificationList?.length === 0" class="no-notifications">
    <p>No notifications available</p>
  </div>
<div *ngFor="let notification of notificationList" class="notification-card" >
  <div class="notification-item">
    <div class="icon">
      <mat-icon color="primary">notifications</mat-icon>
    </div>
    <div class="notification-content">
      <span class="title">{{notification.status}}</span>
      <p>
        {{notification.comment}}      </p>
      <span class="time">{{notification.createdAt | date : "MM/dd/yyyy" || "-"  }}</span>
    </div>
  </div>
</div>
</section>