<section class="wrapper" [ngClass]="{'fit-content-wrapper':showOnlyTable}">
  @if(isMobileFlag && !showOnlyTable){
    <div class="pb-3 fs-24 font-weight-600 ">
      <p>{{accountName}} | {{accountId}}</p>
    </div>
  }
  <div
    [ngClass]="{ 'map-wrapper': viewType == 'mapView' ,'main-wrapper': !isMobileFlag,'border-radius-8': !isMobileFlag && !noTopBorder ,'gray-border':!isMobileFlag,'pt-3':!isMobileFlag,'white_bg': !isMobileFlag, 'no-top-border': noTopBorder , 'no-bottom-border':noBottomBorder}">
    @if(!showOnlyTable){
    <div  [ngClass]="{'px-2': !isMobileFlag}">
      <div class="filter-div">
        <!-- Search  -->
        <div class="lable-input-div px-1">
          <p class="field-lable" *ngIf="!isMobileFlag">Search for order</p>
          <mat-form-field class="input-field" appearance="outline">
            <mat-icon class="search" matPrefix aria-label="Search orders through order id or site address or status" role="img"
              aria-hidden="false"><img src="../../../../assets/v2-asset/search-icon.svg" aria-hidden="true" [ngClass]="{
                  'disable-action': viewType == 'mapView'
                }" /></mat-icon>
            <input matInput (keyup)="onSearch($event)" placeholder="Search" #searchInput
              [disabled]="viewType == 'mapView'" />
          </mat-form-field>
        </div>

        <!-- Status Filter -->
        <div class="lable-input-div px-1">
          <p class="field-lable" *ngIf="!isMobileFlag">Status Filter</p>
          <mat-form-field class="input-field" appearance="outline">
            <mat-select placeholder="Ex. Active" (selectionChange)="onFilter('status',$event)"
              [formControl]="statusFilter" [disabled]="viewType == 'mapView'">
              <mat-option [value]="'All'">All</mat-option>
              <mat-option *ngFor="let status of statusList" [value]="status">{{
                status
                }}</mat-option>
            </mat-select>
            <mat-icon matPrefix role="img" aria-label="Filter orders based on status" aria-hidden="false"
              class="mb-1"><img src="../../../../assets/v2-asset/filter-icon.svg" aria-hidden="true"
                *ngIf="viewType != 'mapView'" />
              <img src="../../../../assets/v2-asset/disabled-filter-icon.svg" aria-hidden="true"
                *ngIf="viewType == 'mapView'" class="disable-action" />
            </mat-icon>
          </mat-form-field>
        </div>
        <!-- project Filter -->
        <div class="lable-input-div px-1" *ngIf="flags?.myussProjectsEnabled && projectEnabled ">
          <p class="field-lable" *ngIf="!isMobileFlag">Project Filter</p>
          <mat-form-field class="input-field" appearance="outline">
            <mat-select placeholder="Ex. Active" (selectionChange)="onFilter('project',$event)"
              [formControl]="projectTypeFilter" [disabled]="viewType == 'mapView'">
              <mat-option [value]="'All'">All</mat-option>
              <!-- <mat-option *ngIf="!isInactiveAccount" [value]="'Orders with no project assign'"
                >Orders with no project assign</mat-option
              > -->
              <mat-option *ngFor="let project of projectList" [value]="project.id">{{ project.name }}</mat-option>
            </mat-select>
            <mat-icon matPrefix role="img" aria-label="Filter orders based on project" aria-hidden="false"  class="mb-1"><img
                src="../../../../assets/v2-asset/filter-icon.svg" aria-hidden="true" *ngIf="viewType != 'mapView'" />
              <img src="../../../../assets/v2-asset/disabled-filter-icon.svg" aria-hidden="true"
                *ngIf="viewType == 'mapView'" class="disable-action" /></mat-icon>
          </mat-form-field>
        </div>
       <!-- view button -->
       @if (flags?.myussProjectsEnabled  ||!isMobileFlag) {
        <div class="view-button-div " [ngClass]="{'button-div':flags?.myussProjectsEnabled && this.projectEnabled }" >
          <div class="view-icon border-radius-8 white_bg" [ngClass]="{'selected-view': viewType == 'listView'}"
            (click)="switchView('listView')">
            <img [ngClass]="{'selected-view-icon': viewType == 'listView'}"
              src="../../../../../../assets/v2-asset/list-view.svg" alt="">
          </div>
          <div class="view-icon border-radius-8 white_bg" [ngClass]="{'selected-view': viewType == 'cardView'}"
            (click)="switchView('cardView')">
            <img [ngClass]="{'selected-view-icon': viewType == 'cardView'}"
              src="../../../../../../assets/v2-asset/card-view.svg" alt="">
          </div>
          <div class="view-icon border-radius-8 white_bg" [ngClass]="{'selected-view': viewType == 'mapView'}"
            (click)="switchView('mapView')">
            <img [ngClass]="{'selected-view-icon': viewType == 'mapView'}"
              src="../../../../../../assets/v2-asset/map-view.svg" alt="">
          </div>
        </div>
       }
      </div>
      @if (!flags?.myussProjectsEnabled && isMobileFlag) {
          <div class="view-button-div view-button-margin ">
            <div class="view-icon border-radius-8 white_bg" [ngClass]="{'selected-view': viewType == 'listView'}"
              (click)="switchView('listView')">
              <img [ngClass]="{'selected-view-icon': viewType == 'listView'}"
                src="../../../../../../assets/v2-asset/list-view.svg" alt="">
            </div>
            <div class="view-icon border-radius-8 white_bg" [ngClass]="{'selected-view': viewType == 'cardView'}"
              (click)="switchView('cardView')">
              <img [ngClass]="{'selected-view-icon': viewType == 'cardView'}"
                src="../../../../../../assets/v2-asset/card-view.svg" alt="">
            </div>
            <div class="view-icon border-radius-8 white_bg" [ngClass]="{'selected-view': viewType == 'mapView'}"
              (click)="switchView('mapView')">
              <img [ngClass]="{'selected-view-icon': viewType == 'mapView'}"
                src="../../../../../../assets/v2-asset/map-view.svg" alt="">
            </div>
          </div>
      }
    
      

    </div>
    <div *ngIf="viewType == 'mapView'" class="map-view">
      <app-contract-location [showLabel]="false" [siteAddress]="siteAddress"></app-contract-location>
    </div>
  }
<!-- Table ui -->
    <div *ngIf="viewType == 'listView' && !isMobileFlag" class="mat-elevation-z0 white_bg table-wrapper">
      <table mat-table [dataSource]="dataSource" matSort>
        <!-- CHECKBOX -->
        @if(showCheckbox){ 
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox (change)="$event ? toggleAllRows() : null"
                          [checked]="selection.hasValue() && isAllSelected()"
                          [indeterminate]="selection.hasValue() && !isAllSelected()">
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()"
                          (change)="$event ? updateSelection(row) : null"
                          [checked]="selection.isSelected(row)">
            </mat-checkbox>
          </td>
        </ng-container>
        }
        <!-- ORDER - ID COLUMN -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Order ID Number</th>
          <td mat-cell *matCellDef="let row">
            <p class="order-id" [ngClass]="{'font-weight-600':endPoint=='/easypay'}">{{ row.name || "-" }}</p>
          </td>
        </ng-container>

        <!-- SITE ADDRESS COLUMN -->
        <ng-container matColumnDef="shippingAddress">
          <th mat-header-cell *matHeaderCellDef>Site Address</th>
          <td mat-cell *matCellDef="let row">
              {{ row.shippingAddress || "-" }}
          </td>
        </ng-container>

        <!-- PAYMENT METHOD COLUMN -->
        <ng-container matColumnDef="cardNo">
          <th mat-header-cell *matHeaderCellDef>Payment Method</th>
          <td mat-cell *matCellDef="let row">
            <div fxLayoutAlign="start center" fxLayoutGap="8px">
              <div>
                <p *ngIf="row.easyPayDetails?.cardNo" class="pr-2">
                  <span *ngIf="row.easyPayMode == 'card'" class="p-1 pl-1" fxLayoutAlign="start center">
                    <img [src]="
                        paymentMethodIcon +
                        row.easyPayDetails?.cardBrand +
                        '.png'
                      " alt="Payment method card logo" *ngIf="
                        [
                          'jcb',
                          'discover',
                          'diners',
                          'visa',
                          'mastercard',
                          'amex'
                        ].includes(row.easyPayDetails?.cardBrand)
                      " height="20px" class="pl-1 card-logo" />
                    <img *ngIf="
                        ![
                          'jcb',
                          'discover',
                          'diners',
                          'visa',
                          'mastercard',
                          'amex'
                        ].includes(row.easyPayDetails?.cardBrand)
                      " class="pl-1 card-logo" src="../../../../assets/v2-asset/payment-icons/visa.png" aria-hidden="true" />
                  </span>

                  <img src="../../../../assets/v2-asset/bank-icon.svg" aria-hidden="true"
                    *ngIf="row.easyPayMode == 'bank'" aria-label="Payment method bank logo" role="img"
                    aria-hidden="false" />
                </p>
              </div>
              <div fxLayout="row" fxLayoutAlign="center center">
                <p *ngIf="row.easyPayDetails?.cardNo">****</p>
                <p class="cardNo_div">
                  {{
                  row.easyPayDetails?.cardNo
                  ? row.easyPayDetails?.cardNo
                  : "Manual"
                  }}
                </p>
              </div>
            </div>
          </td>
        </ng-container>

        <!-- STATUS COLUMN -->
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef>Status</th>
          <td mat-cell *matCellDef="let row">
            <div *ngIf="
                row.status == 'Active' ||
                  row.status == 'Canceled' ||
                  row.status == 'Pending Delivery';
                else other_Status
              ">
              <div *ngIf="row.status == 'Active' && row.contractNumber" class="active-status px-3">
                {{ row.status }}
              </div>
              <div *ngIf="row.status == 'Active' && row.contractNumber == ''" class="other-status px-3">
                In-progress
              </div>

              <div *ngIf="row.status == 'Canceled'" class="cancel-status px-3">
                {{ row.status }}
              </div>

              <div *ngIf="row.status == 'Pending Delivery'" class="other-status px-2">
                {{ row.status }}
              </div>
            </div>
            <ng-template #other_Status>
              <div class="other-status px-3">
                {{ row.status }}
              </div>
            </ng-template>
          </td>
        </ng-container>

        <!-- OPEN CASES COLUMN -->
        <ng-container matColumnDef="casesCount">
          <th mat-header-cell *matHeaderCellDef>Opens Cases</th>
          <td mat-cell *matCellDef="let row">
            <button class="case-count-btn" id="additional-services-count" [matMenuTriggerFor]="additionalServiceMenu"
              (click)="getCaseTypeCount(row.caseTypeWiseCasesCount)" [ngClass]="{
                'no-case-count': row.casesCount === 0
              }">
              {{ row.casesCount }}
            </button>
            <mat-menu #additionalServiceMenu="matMenu" yPosition="below" class="menu">
              <div *ngIf="row.casesCount !== 0">
                <div class="case-type">
                  <img src="../../../../../assets/img/ReduceQuantity.svg" alt="Change Quantity image" />
                  <p class="case-name">
                    {{ caseTypeList[0].caseType }}:
                    {{ caseTypeList[0].caseCount }}
                  </p>
                </div>
                <div class="divider"></div>
                <div class="case-type">
                  <img src="../../../../../assets/img/AddQuantity.svg" alt="Change Quantity image" />
                  <p class="case-name">
                    {{ caseTypeList[1].caseType }}:
                    {{ caseTypeList[1].caseCount }}
                  </p>
                </div>
                <div class="divider"></div>
                <div class="case-type">
                  <img src="../../../../../assets/img/AddNewUnit.svg" alt="Add New Unit Type image" />
                  <p class="case-name">
                    {{ caseTypeList[2].caseType }}:
                    {{ caseTypeList[2].caseCount }}
                  </p>
                </div>

                <div class="divider"></div>
                <div class="case-type">
                  <img src="../../../../../assets/img/ChangeServiceFrequency.svg"
                    alt="Change Service Frequency image" />
                  <p class="case-name">
                    {{ caseTypeList[3].caseType }}:
                    {{ caseTypeList[3].caseCount }}
                  </p>
                </div>

                <div class="divider"></div>
                <div class="case-type">
                  <img src="../../../../../assets/img/Move.svg" alt="Move image" />
                  <p class="case-name">
                    {{ caseTypeList[4].caseType }}:
                    {{ caseTypeList[4].caseCount }}
                  </p>
                </div>
                <div class="divider"></div>
              </div>
            </mat-menu>
          </td>
        </ng-container>
        <!-- EASYPAY STATUS COLUMN -->
        <ng-container matColumnDef="isAutoPay" >
          <th
            mat-header-cell
            *matHeaderCellDef
            class="table-header"
            mat-sort-header
          >
            Status
          </th>
          <td mat-cell *matCellDef="let element">

              <div
                class=" div_width justify-content-center"
                [ngClass]="{
                  'active-status': element.isAutoPay === true,
                  'other-status': element.isAutoPay === false,
                  'px-3': element.isAutoPay === true,
                  'px-2': element.isAutoPay === false
                }"
                *ngIf="!element.easyPayDetails?.expired"
              >
                {{ element.isAutoPay ? "Active" : "Inactive" }}
              </div>

              <div
                class=" px-3 div_width expired justify-content-center"
                *ngIf="element.easyPayDetails?.expired"
              >
                Expired
              </div>
          </td>
        </ng-container>

        <!-- RECURRING AMOUNT COLUMN -->
        <ng-container matColumnDef="recurringAmount">
          <th mat-header-cell *matHeaderCellDef>Recurring Amount</th>
          <td mat-cell *matCellDef="let row">
              {{ row.recurringSubtotal | currency:'USD':'symbol'  }}
          </td>
        </ng-container>

        <!-- INVOICE DUE DATE COLUMN -->
        <ng-container matColumnDef="invoiceDueDate">
          <th mat-header-cell *matHeaderCellDef>Invoive Due Date</th>
          <td mat-cell *matCellDef="let row">
              {{ row.nextInvoiceDate }}
          </td>
        </ng-container>

        <!-- ACTION COLUMN -->
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef>Action</th>
          <td mat-cell *matCellDef="let row">
            <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="8px">
              <ng-container>
                <img  class="icons"  [ngClass]="{'disable-action': row.contractNumber == ''}" (click)="onView(row)" src="../../../../assets/v2-asset/eye-icon.svg" alt="Open item to view details"
                   />
                <img  class="icons" src="../../../../assets/v2-asset/pin-icon.svg"
                  alt="View site location and related orders." (click)="openMap(row.shippingAddress)"
                  [ngClass]="{
                    'disable-action': row.status === 'Canceled'
                  }" />
                <img  *ngIf="!isInactiveAccount && flags.myussQuotesEnabled" src="../../../../assets/v2-asset/clone-icon.svg" alt="Clone this order"
                class="icons" (click)="onClone(row.quoteId)" [ngClass]="{
                    'disable-action': isInactiveAccount
                  }" />
                <img src="../../../../../../assets/v2-asset/cancel.svg" class="icons" *ngIf="!isInactiveAccount"
                  [ngClass]="{'disable-action': row.status == 'Canceled' || row.isCancelButtonFlag || row.contractNumber == '' }"
                  (click)="orderService.onCancelContract(row)" alt="Cancel this order">
              </ng-container>
            </div>
          </td>
        </ng-container>

        <tr class="table-header" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" id="noDataTag" *matNoDataRow>
          <td class="mat-cell p-2 pl-3" colspan="9">No Data Available.</td>
        </tr>
      </table>

      <mat-paginator [pageSizeOptions]="[10]" aria-label="Select page of users"></mat-paginator>
    </div>

<!-- card ui  -->
    <div *ngIf="viewType == 'cardView' || (viewType == 'listView' && isMobileFlag)" class="card-wrapper row g-3">
      <div class="custom_grid">
      @for (item of cardViewOrderList;track $index;) {
      <div class="card-div ">
        <app-card [footer]="true" [allowCollapse]="(viewType == 'listView' && isMobileFlag)?true:false"
          [isExpanded]="(viewType == 'listView' && isMobileFlag)? selectedCard== $index? true:false :true"
          (CollpaseEvent)="collapseClick($event,$index)"
          [showCollapseIcon]="false" [collapseIconColor]="selectedCard== $index? '#006DCC': '' " [backgroundColor]="selectedCard== $index? '#F3F9FF': '#FFFFFF' " [borderColor]="selectedCard== $index? '#00539B80': '#D5D7DA' ">
          <div card-header>
            <div class="d-flex justify-content-between align-items-center">
              <div class="d-flex justify-content-start align-items-center">
                <!-- <div class="blue-box me-1"></div> -->
                <p class="fs-16 font-weight-600 order-name-font-color " [ngClass]="{'fs-20':isMobileFlag}">{{item.name}}</p>
                <div *ngIf="viewType == 'listView' && isMobileFlag" class="px-2 ms-3"
                  [ngClass]="{'other-status':item.status != 'Canceled' && item.status != 'Active','active-status': item.status == 'Active','cancel-status': item.status == 'Canceled'}">
                  {{item.status}}
                </div>
              </div>
              <div *ngIf="viewType == 'cardView'" class="px-2 me-2"
                [ngClass]="{'other-status':item.status != 'Canceled' && item.status != 'Active','active-status': item.status == 'Active','cancel-status': item.status == 'Canceled'}">
                {{item.status}}
              </div>
            </div>

          </div>
          <div card-content>
            <p class="address-p pb-2">{{item.shippingAddress}}</p>
            <!-- <p>Payment Method {{}}</p> -->
            <div fxLayoutAlign="start center">
              <p class="font-weight-400 text-color" [ngClass]="{'fs-16': isMobileFlag}">Payment Method</p>
              <div fxLayoutAlign="start center" fxLayoutGap="8px">
                <div>
                  <p *ngIf="item.easyPayDetails?.cardNo" class="pr-2">
                    <span *ngIf="item.easyPayMode == 'card'" class="p-1 pl-1" fxLayoutAlign="start center">
                      <img [src]="
                            paymentMethodIcon +
                            item.easyPayDetails?.cardBrand +
                            '.png'
                          " alt="Payment method card logo" *ngIf="
                            [
                              'jcb',
                              'discover',
                              'diners',
                              'visa',
                              'mastercard',
                              'amex'
                            ].includes(item.easyPayDetails?.cardBrand!)
                          " height="20px" class="pl-1 card-logo" />
                      <img *ngIf="
                            ![
                              'jcb',
                              'discover',
                              'diners',
                              'visa',
                              'mastercard',
                              'amex'
                            ].includes(item.easyPayDetails?.cardBrand!)
                          " class="pl-1 card-logo" src="../../../../assets/v2-asset/payment-icons/visa.png" aria-hidden="true" />
                    </span>

                    <img src="../../../../assets/v2-asset/bank-icon.svg" aria-hidden="true"
                      *ngIf="item.easyPayMode == 'bank'" aria-label="Payment method bank logo" role="img"
                      aria-hidden="false" />
                  </p>
                </div>
                <div fxLayout="row" fxLayoutAlign="center center">
                  <p *ngIf="item.easyPayDetails?.cardNo" class="gray-600">****</p>
                  <p class="cardNo_div gray-600">
                    {{
                    item.easyPayDetails?.cardNo
                    ? item.easyPayDetails?.cardNo
                    : "Manual"
                    }}
                  </p>
                </div>
              </div>
            </div>

            <!-- cases -->
            <div fxLayoutAlign="start center">
              <p class="font-weight-400 text-color" [ngClass]="{'fs-16': isMobileFlag}">Open cases</p>
              <button class="case-count-btn" id="additional-services-count" [matMenuTriggerFor]="additionalServiceMenu"
                (click)="getCaseTypeCount(item.caseTypeWiseCasesCount)" [ngClass]="{
                    'no-case-count': item.casesCount === 0
                  }">
                {{ item.casesCount }}
              </button>
              <mat-menu #additionalServiceMenu="matMenu" yPosition="below" class="menu">
                <div *ngIf="item.casesCount !== 0">
                  <div class="case-type">
                    <img src="../../../../../assets/img/ReduceQuantity.svg" alt="Change Quantity image" />
                    <p class="case-name">
                      {{ caseTypeList[0].caseType }}:
                      {{ caseTypeList[0].caseCount }}
                    </p>
                  </div>
                  <div class="divider"></div>
                  <div class="case-type">
                    <img src="../../../../../assets/img/AddQuantity.svg" alt="Change Quantity image" />
                    <p class="case-name">
                      {{ caseTypeList[1].caseType }}:
                      {{ caseTypeList[1].caseCount }}
                    </p>
                  </div>
                  <div class="divider"></div>
                  <div class="case-type">
                    <img src="../../../../../assets/img/AddNewUnit.svg" alt="Add New Unit Type image" />
                    <p class="case-name">
                      {{ caseTypeList[2].caseType }}:
                      {{ caseTypeList[2].caseCount }}
                    </p>
                  </div>

                  <div class="divider"></div>
                  <div class="case-type">
                    <img src="../../../../../assets/img/ChangeServiceFrequency.svg"
                      alt="Change Service Frequency image" />
                    <p class="case-name">
                      {{ caseTypeList[3].caseType }}:
                      {{ caseTypeList[3].caseCount }}
                    </p>
                  </div>

                  <div class="divider"></div>
                  <div class="case-type">
                    <img src="../../../../../assets/img/Move.svg" alt="Move image" />
                    <p class="case-name">
                      {{ caseTypeList[4].caseType }}:
                      {{ caseTypeList[4].caseCount }}
                    </p>
                  </div>
                  <div class="divider"></div>
                </div>
              </mat-menu>
            </div>


          </div>
          <div card-collapse-content>
            <p class="address-p" >{{item.shippingAddress}}</p>
          </div>
          <div card-footer>
            <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="8px" class="px-3">
              <ng-container>
                <img  class="icons" [ngClass]="{'disable-action': item.contractNumber == ''}" (click)="onView(item)" src="../../../../assets/v2-asset/eye-icon.svg" alt="Open item to view details"
                   />
               
                <img  class="icons" src="../../../../assets/v2-asset/pin-icon.svg"
                  alt="View site location and related orders."  (click)="openMap(item.shippingAddress)"
                  [ngClass]="{
                      'disable-action': item.status === 'Canceled'
                    }" />
                <img  class="icons" *ngIf="!isInactiveAccount && flags.myussQuotesEnabled" src="../../../../assets/v2-asset/clone-icon.svg" alt="Clone this order"
                   (click)="onClone(item.quoteId)" [ngClass]="{
                      'disable-action': isInactiveAccount
                    }" />
                <img  *ngIf="!isInactiveAccount" class="icons" src="../../../../../../assets/v2-asset/cancel.svg"
                  [ngClass]="{'disable-action': item.status == 'Canceled' || item.isCancelButtonFlag || item.contractNumber == '' }"
                  (click)="orderService.onCancelContract(item)" alt="Cancel this order">
              </ng-container>
            </div>
          </div>
        </app-card>
      </div>
    }
    
    @if(cardViewOrderList?.length == 0){
      <div>
        <p>No Data Available.</p>
      </div>
    }
  </div>

      <mat-paginator #contractsListPaginator [length]="orderFilterList?.length" [pageSize]="pageSize"
        [pageSizeOptions]="[pageSizeOption]" (page)="onPageChange($event)" [showFirstLastButtons]="true" [hidePageSize]="isMobileFlag?true:false">
      </mat-paginator>
    </div>

  </div>

</section>
@if(!showOnlyTable){
<div class="row g-3 bottom-cards ">
  @for(link of links; track link){
  <div class="col-12 col-xxl-3 col-xl-3 col-md-6 col-lg-4 col-sm-6">
    <app-link-card [title]="link.title" [navigationLink]="link.navigationLink" [icon]="link.icon"></app-link-card>
  </div>
  }
</div>
}