import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  SimpleChanges,
  ViewChild,
  effect,
  output,
} from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { MatTableModule } from "@angular/material/table";
import {
  MatPaginator,
  MatPaginatorModule,
  PageEvent,
} from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { CommonModule } from "@angular/common";
import { caseTypes, links } from "src/assets/config/constants";
import { MatDialog } from "@angular/material/dialog";
import { FormControl } from "@angular/forms";
import { SelectionModel } from "@angular/cdk/collections";
import { ActivatedRoute, Router } from "@angular/router";
import { SelectProjectComponent } from "src/app/shared/components/dialogbox/select-project/select-project.component";
import { ProjectModel } from "src/app/models/project-model";
import { ToastrService } from "ngx-toastr";
import { ProjectService } from "src/app/features/projects/services/project.service";
import { ConfigService } from "src/app/shared/services/config/config.service";
import { ProfileService } from "src/app/features/accounts/services/profile/profile-services.service";
import { MatFormField } from "@angular/material/form-field";
import { MatIcon } from "@angular/material/icon";
import { MatLabel } from "@angular/material/form-field";
import { MatSelect, MatSelectChange } from "@angular/material/select";
import { MatSelectModule } from "@angular/material/select";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { MatSlideToggle } from "@angular/material/slide-toggle";
import { MatMenuModule } from "@angular/material/menu";
import { ContractLocationComponent } from "src/app/v2/shared/components/contract-location/contract-location.component";
import { FlexLayoutModule } from "@ngbracket/ngx-layout";
import { MatSortHeader } from "@angular/material/sort";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatTooltipModule } from "@angular/material/tooltip";
import { ContractList } from "src/app/v2/models/contract-model";
import {
  MatCheckboxModule,
} from "@angular/material/checkbox";
import { OrderSummaryTabsComponent } from "../order-summary-tabs/order-summary-tabs.component";
import { MatButtonModule } from "@angular/material/button";
import { OrderService } from "../../services/order/order.service";
import { CardComponent } from "../../../../shared/components/card/card.component";
import { LinkCardComponent } from "../../../home/components/link-card/link-card.component";

@Component({
  selector: "app-orders",
  standalone: true,
  imports: [
    MatTableModule,
    CommonModule,
    MatFormField,
    MatIcon,
    MatLabel,
    MatSelect,
    MatSelectModule,
    ReactiveFormsModule,
    FormsModule,
    MatSlideToggle,
    MatMenuModule,
    MatPaginatorModule,
    ContractLocationComponent,
    FlexLayoutModule,
    MatSort,
    MatPaginator,
    MatSortHeader,
    MatFormFieldModule,
    MatInputModule,
    MatTooltipModule,
    MatCheckboxModule,
    OrderSummaryTabsComponent,
    MatButtonModule,
    CardComponent,
    LinkCardComponent,
  ],
  templateUrl: "./orders.component.html",
  styleUrl: "./orders.component.scss",
})
export class OrdersComponent {
  displayedColumns: string[] = [
    "name",
    "shippingAddress",
    "cardNo",
    "status",
    "casesCount",
    "action",
  ];
  dataSource: MatTableDataSource<ContractList>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild("contractsListPaginator") contractsListPaginator: MatPaginator;
  @ViewChild("searchInput") searchInput: ElementRef;
  orderList: ContractList[] = [];
  orderFilterList: ContractList[] = [];
  cardViewOrderList: ContractList[] = [];
  paymentMethodIcon: string;
  caseTypeList: { caseType: string; caseCount: number }[];
  statusFilter = new FormControl("All");
  projectTypeFilter = new FormControl("All");
  statusList: string[] = [];
  quoteIds: string[] = [];
  projectList: ProjectModel[] = [];
  projectId: string = "";
  projectEnabled: boolean;
  isInactiveAccount: boolean = false;
  siteAddress: string = "";
  flags: Record<string, boolean | undefined>;
  currentPageIndex: number = 0;
  userId: string;
  viewType: string = "listView";
  orderListStatus: string = "Activated,Suspended,Draft,Canceled";
  currentDate = new Date();
  pageSize = 12;
  pageSizeOption = 12;
  currentPage = 0;
  isMobileFlag: boolean = true;
  links:Record<string,string>[] = [];
  selectedCard: number | null = 0;
  private storageKey = 'orderCaseCounts';
  accountName: string;
  accountId : string
  selection = new SelectionModel<ContractList>(true, []);
  endPoint:string;
  //To show only table 
  @Input() showOnlyTable:boolean=false;
  //To show checkbox to select rows or all 
  @Input() showCheckbox:boolean=false;
  //Show only shared orders from parent component
  @Input() sharedOrders:ContractList[] | undefined;
  //To hide top border of main-wrapper
  @Input() noTopBorder:boolean=false;
  //To send order data to parent component
  @Input() noBottomBorder:boolean=false;
  //To send order data to parent component
  ordersData= output<ContractList[] | null>()
  //send selected rows to parent component
  selectedRows= output<ContractList[] | null>()
  

  constructor(
    public orderService: OrderService,
    private box: MatDialog,
    private router: Router,
    private toastr: ToastrService,
    public projectService: ProjectService,
    private configService: ConfigService,
    private Activatedroute: ActivatedRoute,
    private profileService: ProfileService,
  ) 
  {
    this.projectEnabled =
      this.configService.getConfigProperty("PROJECTS_ENABLED");
    this.Activatedroute.queryParams.subscribe((params) => {
      if (params.projectId) {
        this.projectId = params.projectId;
        this.projectTypeFilter.setValue(this.projectId);
      }
      localStorage.setItem("caseCount", JSON.stringify(this.storageKey));
    });
    effect(async () => {
      this.isInactiveAccount =
        this.profileService?.selectedAccount().myussUserRole === "Read Only" ||
        this.profileService.isAccountInActive();
      this.projectTypeFilter = new FormControl("All");
      const account = this.profileService.selectedAccount();
      this.flags = await this.profileService.getAllMyussFlags();
      if (!this.projectId) {
        this.projectService.setSelectedProject(new ProjectModel());
      }

      if (account.accountId != this.userId) {
        this.accountId = profileService.selectedAccount().accountNumber as string;
        this.accountName = profileService.selectedAccount().accountName as string;
        this.fetchOrderList(this.orderListStatus);
        this.statusFilter = new FormControl("All");
        this.userId = account.accountId || "";
        if (this.flags?.myussProjectsEnabled && this.projectEnabled) {
          this.fetchProjects("Active");
          this.getProjectData();
        }
      }
      this.viewType = "listView";
      1;
    });
  }

  async ngOnInit() {
    this.endPoint = this.router.url;
    this.updateMobileUi();
    this.paymentMethodIcon = "../../../../assets/v2-asset/payment-icons/";
    this.caseTypeList = caseTypes;
    this.flags = await this.profileService.getAllMyussFlags();
    this.links=links;
    this.updateDisplayColumns();
    if(this.endPoint == "/easypay"){
      this.orderListStatus = "Activated,Suspended,Draft"
    }
  }

  ngOnChanges(){
    if(this.sharedOrders?.length){
      this.updateDataSource(this.sharedOrders);
      //Displayed columns are for easy pay
      this.displayedColumns = [
        "name",
        "isAutoPay",
        "cardNo",
        "recurringAmount",
        "invoiceDueDate",
        "shippingAddress",
      ];
    }
    else{
      this.updateDataSource(this.orderList);
      this.updateDisplayColumns();
    }
  }

  fetchOrderList(status: string) {
    this.orderService.getAllContract(status).subscribe((res) => {
      this.orderList = this.orderService.disableCancelButton(res);
      this.orderList = this.orderList.sort((a, b) =>
        b.name.localeCompare(a.name)
      );
      
      this.orderFilterList = this.orderList;
      if(this.sharedOrders?.length){
        this.updateDataSource(this.sharedOrders);
      }else{
      this.updateDataSource(this.orderList);
    }
      this.statusList = [
        ...new Set(
          this.orderList.map((contract: ContractList) => contract.status)
        ),
        "In-progress",
      ] as string[];
      this.projectTypeFilter.setValue("All");
      //emit order data to parent
      this.ordersData.emit(this.orderList);
    });
  }

  getCaseTypeCount(caseArray) {
    caseTypes.forEach((itemA) => {
      let match = caseArray.find(
        (itemB) => itemB.MyUSS_Case_Type__c === itemA.caseType
      );
      if (match) {
        itemA.caseCount = match.caseCount;
      } else {
        itemA.caseCount = 0;
      }
    });
  }

  onSearch(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    this.cardViewOrderList = this.dataSource.filteredData;

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
    this.dataSource.filterPredicate = (data: ContractList, filter: string) => {
     
   
      const dataStr = JSON.stringify(data)?.toLowerCase();
      return dataStr?.indexOf(filter?.toLowerCase()) !== -1;
    };
  }

  updateDataSource(data: ContractList[]): void {
    this.dataSource = new MatTableDataSource(data);
    this.dataSource.paginator = this.paginator;
    if (this.paginator) {
      this.paginator.pageIndex = 0;
    }
    this.dataSource.sort = this.sort;
    this.cardViewOrderList = this.dataSource.filteredData.slice(
      0,
      this.pageSizeOption
    );
  }

  updateDisplayColumns() {
    if (this.projectTypeFilter.value === "Orders with no project assign") {
      this.displayedColumns = [
        "select",
        "name",
        "shippingAddress",
        "cardNo",
        "status",
        "casesCount",
        "action",
      ];
    }else if(this.showCheckbox){ //IF SHOWCHECKBOX IS TRUE THEN SHOW CHECKBOX IN TABLE
      this.displayedColumns = [
        "select",
        "name",
        "isAutoPay",
        "cardNo",
        "recurringAmount",
        "invoiceDueDate",
        "shippingAddress",
      ];
    }
    else {
      this.displayedColumns = [
        "name",
        "shippingAddress",
        "cardNo",
        "status",
        "casesCount",
        "action",
      ];
    }
  }

  fetchProjects(status: string) {
    this.projectService.fetchProjects(status).subscribe();
  }

  getProjectData() {
    this.projectService.projectData$.subscribe((projects) => {
      if (projects) {
        this.projectList = projects;
      }
    });
  }

  onClone(quoteId: string) {}

  openMap(shippingAddress: string) {
    this.viewType = "mapView";
    this.siteAddress = shippingAddress;
  }

  switchView(view: string) {
    this.viewType = view;
    this.statusFilter.patchValue('All');
    this.projectTypeFilter.patchValue('All');
    this.searchInput.nativeElement.value = "";
    this.siteAddress = ""

      if (view == "listView") {
        this.selectedCard = 0;
              }
    if (view == "cardView") {
      this.selectedCard = null;
    }
    setTimeout(() => {
      this.updateDataSource(this.orderList);
    }, 500);

  }

  onFilter(type: string, event: MatSelectChange) {
    this.searchInput.nativeElement.value = "";
    switch (type) {
      case "status":
        if (event.value == "All") {
          this.updateDataSource(this.orderFilterList);
          return;
        }
        this.updateDataSource(
          this.orderFilterList.filter((list) => list.status == event.value)
        );

        break;
      case "project":
        this.statusFilter.patchValue('All');
        if (event.value == "All") {
          this.orderFilterList = this.orderList;
          this.updateDataSource(this.orderList);
          return;
        }
        this.orderFilterList = this.orderList.filter(
          (list) => list.projectDetails?.id == event.value
        );
        this.updateDataSource(this.orderFilterList);
        break;
    }
  }

  // For contracts
  onPageChange(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.paginateData();
  }

  paginateData() {
    const startIndex = this.currentPage * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    this.cardViewOrderList = this.orderList.slice(startIndex, endIndex);
  }

  @HostListener("window:resize") onWindowResize(): void {
    this.updateMobileUi();
  }

  updateMobileUi() {
    if (window.innerWidth <= 767) {
      this.cardViewOrderList = this.dataSource?.filteredData?.slice(
        0,
        this.pageSizeOption
      );

      this.isMobileFlag = true;
      this.pageSizeOption = 5;
      this.pageSize = 5;
    } else {
      this.cardViewOrderList = this.dataSource?.filteredData?.slice(
        0,
        this.pageSizeOption
      );
      this.isMobileFlag = false;
      this.pageSizeOption = 12;
      this.pageSize = 12;
    }
  }
  collapseClick(event: boolean, i: number) {
    this.selectedCard = i;
  }

  private saveCaseCount(contractId: string, caseCount: number): void {
    const savedData = localStorage.getItem(this.storageKey);
    const contractData: Record<string, number> = savedData ? JSON.parse(savedData) : {};
  
    Object.keys(contractData).forEach(key => delete contractData[key]);
    contractData[contractId] = caseCount;
  
    localStorage.setItem(this.storageKey, JSON.stringify(contractData));
  }

  onView(row:ContractList) {
    this.router.navigate(["orders/ordersummary", row.contractId]);
    const contractId = row.contractId;
    const caseCount = row.casesCount?row.casesCount:0;

    this.saveCaseCount(contractId, caseCount);
  }

   /** Whether the number of selected elements matches the total number of rows. */
   isAllSelected() {
    const numSelected = this.selection?.selected?.length;
    const numRows = this.dataSource?.data?.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
    this.selectedRows.emit(this.selection.selected);
  }
  //Add selected ros in slection model and emit to parent component
  updateSelection(row){
    this.selection.toggle(row)
    this.selectedRows.emit(this.selection.selected);
  }

  ngOnDestroy(){
    this.sharedOrders=[];
  }

}
