import { Component, NgZone } from "@angular/core";
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { AppSettings } from "../../../../app.settings";
import { Settings } from "../../../../app.settings.model";
import { ToastrService } from "ngx-toastr";
import { PostalCode } from "../../../../models/zipcode";
import { ZipcodeService } from "src/app/features/myuss/services/zipcode/zipcode.service";
import { AuthenticationService } from "src/app/shared/services/authentication/authentication.service";
import { Platform } from "@ionic/angular";
import { appDisplayName } from "src/assets/config/constants";

@Component({
  selector: "app-zip-search",
  templateUrl: "./zip-search.component.html",
  styleUrls: ["./zip-search.component.scss"],
})
export class ZipSearchComponent {
  error: boolean = false;
  error2: boolean = false;
  loader: boolean = false;
  isNative: boolean;
  applicationTitle = appDisplayName;

  public form: UntypedFormGroup;
  public settings: Settings;
  constructor(
    public appSettings: AppSettings,
    public fb: UntypedFormBuilder,
    public router: Router,
    private zipcodeservice: ZipcodeService,
    public toastr: ToastrService,
    private auth: AuthenticationService,
    private platform: Platform,
  ) {
    this.settings = this.appSettings.settings;
    this.isNative = platform.is("hybrid");
    this.form = this.fb.group({
      postalCode: [
        null,
        Validators.compose([Validators.required, Validators.minLength(5)]),
      ],
    });
  }
  ngOnInit() {
    if(this.isNative){
      this.auth.authenticationChange$.subscribe((auth) => {
        console.log("auth change", auth);
        if(auth){
          this.router.navigateByUrl("/home");
        }
      })
    }
  }

  //restrict special characters and charaters from input fields
  onkey(e) {
    console.log(e);
    this.form.patchValue({ postalCode: e.replace(/[^0-9]/g, "") });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.settings.loadingSpinner = false;
    });
  }
  // on submit button click api call for verification
  async onSubmit(form) {
    this.error2 = false;
    this.error = false;
    if (this.form.valid) {
      const code = this.form.value as PostalCode;
      this.zipcodeservice.zipcodeValidation(code).subscribe((res) => {
        if (res?.data["isServiceable"]) {
          this.router.navigateByUrl("/loading");
          this.login()
        } else {
          this.error = false;
        }
      });
      // this.zipcodeservice.zipcodeResult.subscribe((res)=>{
      //   if (res?.data["isServiceable"]) {
      //     this.router.navigateByUrl("/loading");
      //   } else {
      //     this.error = false;
      //   }
      // })
    } else {
      this.error = true;
    }
  }
  login() {
    this.auth.login();
  }
}
