<article class="dashboard-card" [ngClass]="{'gap-3': !(tileInfo.number)}">
  <div class="card-header">
    <h2 class="card-title">{{ tileInfo?.title }}</h2>
    <div class="card-icon-wrapper">
      <img [src]="tileInfo.icon" alt="" />
    </div>
  </div>
  <div class="card-content">
    <p class="card-number">{{ tileInfo?.number }}</p>
    <p class="card-description" [innerHTML]="tileInfo.description"></p>
  </div>
</article>
