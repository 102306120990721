<div class="custom-card p-3">
  <div class="bg-primary">
    <h3 class="popup-header-text ps-2" *ngIf="!this.cancelOrderData.isShowCalendar">
      Cancel Order
    </h3>
    <h3 class="popup-header-text ps-2" *ngIf="
        this.cancelOrderData.isShowCalendar &&
        this.cancelOrderData.isShowCloseBtn
      ">
      Close Order
    </h3>
    <h3 class="popup-header-text ps-2" *ngIf="
        this.cancelOrderData.isShowCalendar &&
        !this.cancelOrderData.isShowCloseBtn
      ">
      Change End Date
    </h3>
  </div>

  <div class="m-2">
    <p class="info pb-2" *ngIf="this.cancelOrderData.showCancelationNote">
      Note: All units on the site are requested for pickup, which in effect have
      lead to cancellation of the order.
    </p>
    <div [formGroup]="cancelOrderForm" class="container" >
      <div class="row">
        <label class="font-weight-500 col-4 pl-4 fs-16">Effective Date</label>
        <div class="col-8">
        <mat-form-field class="w-100" color="primary" appearance="outline">
          <img src="assets/v2-asset/calendar.svg" alt="calendar" (click)="datePicker.open()" class="px-2 cursor-pointer " matPrefix />
          <input matInput [min]="minDate" [matDatepicker]="datePicker" placeholder="00/00/0000"
            formControlName="endDate" (dateChange)="checkDayOfWeek($event)" required />
          <mat-datepicker #datePicker ></mat-datepicker>
          <mat-error *ngIf="cancelOrderForm.controls.endDate.errors?.required">
            Date is required.
          </mat-error>
        </mat-form-field>
      </div>
      </div>
      <div class="row">
        <label class="col-4 font-weight-500 pl-4 fs-16">Pickup Reason</label>

        <mat-form-field class="col-8" appearance="outline" color="primary">
          <mat-select formControlName="pickupReasonCode">
            <mat-option *ngFor="let resasonType of reasonTypeList" [value]="resasonType.value">{{ resasonType.name }}
            </mat-option>
          </mat-select>
          <mat-error>Please select the Reason.</mat-error>
        </mat-form-field>
      </div>

      <div class="row">
      <label class="addNoteLabel col-4 font-weight-500 pl-4 fs-16">Additional info</label>

        <mat-form-field class="col-8" appearance="outline">
          <textarea matInput formControlName="note" maxlength="255" #box rows="4"></textarea>

          <mat-hint align="end">{{ box.value.length }}/255</mat-hint>
        </mat-form-field>
      </div>
    </div>

    <div class="d-flex justify-content-end gap-2 popup-footer pb-2 pt-3">
      <button class="no-btn px-2 py-1 fs-14 cursor-pointer" mat-button mat-dialog-close>Cancel</button>
      <button class="confirm-btn py-1 px-2 fs-14 cursor-pointer" mat-raised-button color="primary" 
        (click)="onConfirm()">
        Confirm
      </button>
    </div>
  </div>
</div>