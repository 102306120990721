<div class="wrapper  d-flex align-items-center flex-column">

        <div class="dasboard-card-container row g-3 ">
            @for (item of dashboardCards; track item){
            @if(myussFlags?.[item.flag]){
            <div (click)="navigateTo(item.route)" class="card-wrapper col-12 col-xxl-3 col-xl-3 col-md-6 col-lg-4 col-sm-6">
                <app-tile [tileInfo]="item"></app-tile>
            </div>
            }
    
    
            }
            <img src="assets/v2-asset/banner_placeholder.png"
                alt="" class="col-12 col-xxl-6 col-xl-6 col-md-12 col-lg-8 col-sm-12 banner" />
    
            @if(myussFlags?.myussAssetScanningEnabled){
            <div class="col-6 col-xxl-3 col-xl-3 col-md-6 col-lg-3 col-sm-6">
                <div class="find">
                    <div class="search-img">
                        <img src="assets/v2-asset/magnifying_glass.svg"
                            alt="" />
                    </div>
                    <div class="find-card-header">
                        <span>Find a Unit</span>
                        <span class="material-symbols-outlined info-circle fill info-circle">
                            info
                        </span>
                    </div>
                    <span class="find-card-description">
                        Search by the serial number on the asset tag
                    </span>
                    <button mat-flat-button class="search-btn">
                        Search
                    </button>
                </div>
            </div>
            }
        </div>
        <div class="row g-3 bottom-cards">
            @for(link of links; track link){
            <div  class="col-12 col-xxl-3 col-xl-3 col-md-6 col-lg-4 col-sm-6">
                <app-link-card  [title]="link.title"  [navigationLink]="link.navigationLink"
                    [icon]="link.icon"></app-link-card>
            </div>
            }

        </div>

</div>

